import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import './Finish.css';

const Finish = props => {
  useEffect(() => {
    document.title = `Beam - Finish`;
  }, []);

  const { payrollName, redirectUrl } = useSelector(
    state => state.participant.data
  );
  return (
    <div>
      <section className="Section Section--leading">
        <h1>
          Congratulations! You’re now ready to manage super in your payroll with
          Beam.
        </h1>
        <p>
          Learn more about Beam:{' '}
          <ExternalLink url="https://www.beamconnect.com.au" />
        </p>
      </section>

      <React.Fragment>
        {redirectUrl && redirectUrl.length > 0 && (
          <section className="Section PageNavGroup">
            <a className="Button Button--primary" href={redirectUrl}>
              Back to {payrollName}
            </a>
          </section>
        )}
      </React.Fragment>
    </div>
  );
};

export default Finish;
