import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Callout from '../../components/Callout/Callout';
import ClientEntry from './ClientEntry';
import ClientDetailsModal from './ClientDetailsModal';
import Button from '../../components/Button/Button';
import omit from 'lodash/omit';
import './YourEmployers.css';
import { useSelector, useDispatch } from 'react-redux';
import { handleReturnToPayroll } from '../../store/thunks/global';
import SearchApi from '../../store/api/search';
import {
  resetParticipantData,
  saveParticipantData
} from '../../store/actions/participantActions';
import { saveParticipantDetails } from '../../store/thunks/participant';
import { update } from 'lodash';

const EntryTable = ({ caption, children }) => {
  return (
    <table className="Table--employers">
      <caption>
        <h2>{caption}</h2>
      </caption>
      <thead>
        <tr>
          <th>Status</th>
          <th>ABN/WPN</th>
          <th>Business name</th>
          <th>Bank details provided</th>
          <th>Default fund</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

const YourEmployers = props => {
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [data, setData] = useState([]);
  const [fundNameReference, setFundNameReference] = useState([]);
  const [isClientDetailsOpen, setClientDetailsOpen] = useState(false);
  const [proceedEnabled, setProceedEnabled] = useState(false);
  const dispatch = useDispatch();

  const {
    participant,
    validations,
    payrollName,
    redirectUrl,
    participantCopy,
    validationsCopy,
    loading
  } = useSelector(state => state.participant.data);

  const navigateToNext = () => {
    const { history, location } = props;
    // Navigate to next step
    history.push({
      pathname: '/t&c',
      hash: location.hash
    });
  };

  const openClientDetails = clientEmployerNumber => e => {
    setClientDetailsOpen(true);
    setSelectedEmployer(
      data.find(
        employer => employer.clientEmployerNumber === clientEmployerNumber
      )
    );
  };

  const handleEmployerUpdate = updatedEmployer => {
    let employers = [];
    employers = participant.employers.map(employer => {
      return employer.clientEmployerNumber ===
        updatedEmployer.clientEmployerNumber
        ? omit(updatedEmployer, 'validations')
        : employer;
    });
    saveEmployers(employers);
  };

  // Update employers to participant
  const saveEmployers = employers => {
    dispatch(
      saveParticipantDetails({
        ...participant,
        employers
      })
    );
  };

  const proceedWithSuccessfulEntries = () => {
    //dispatch(saveParticipantData({ participantCopy: participant, validationsCopy: validations }))
    const successfulEntries = data
      .filter(employer => employer.validations.length === 0)
      .map(employer => omit(employer, 'validations'));
    const successfulEntryNumbers = successfulEntries.map(
      entry => entry.clientEmployerNumber
    );

    // dispatch(saveParticipantDetails({
    //   ...participant,
    //   employers: successfulEntries,
    //   directDebitAuthorities: participant.directDebitAuthorities
    //     .filter(
    //       authority =>
    //         authority.employers == null ||
    //         authority.employers.length === 0 ||
    //         authority.employers.find(employer =>
    //           successfulEntryNumbers.includes(employer.clientEmployerNumber)
    //         )
    //     )
    //     .map(employer => omit(employer, 'validations'))
    // }));

    setProceedEnabled(true);
  };

  const closeClientDetails = () => {
    setClientDetailsOpen(false);
    setSelectedEmployer(null);
  };

  const initData = async (participantData, validationsData) => {
    let employerValidations = validationsData
      ? validationsData.filter(
          validation => validation.path.indexOf('employers') > -1
        )
      : [];

    const data = participantData.employers
      // Extend each authority with its own validation errors
      .map((employer, index) => {
        return {
          // TODO: enable when DDA form section is added
          // directDebitAuthorities: [],
          ...employer,
          validations: [
            ...employerValidations.filter(error =>
              error.path.includes(`employers[${index}]`)
            )
          ]
        };
      });

    setData(data);

    let defaultFunds = [];
    let fundNames = {};

    participantData.employers.forEach(employer => {
      employer.defaultFunds.forEach(fund => defaultFunds.push(fund.usi));
    });

    if (defaultFunds.length > 0) {
      // TODO
      const res = await SearchApi.getFundsDetails(defaultFunds);
      res.filter(fund => fund.fundMatchResults != null).forEach(result => {
        fundNames[result.fundQuery] = result.fundMatchResults[0].fundName;
      });
      setFundNameReference(fundNames);
    }
  };

  useEffect(
    () => {
      if (proceedEnabled && !loading && validations.length === 0) {
        navigateToNext();
      }
      if (proceedEnabled && !loading && validations.length > 0) {
        setProceedEnabled(false);
      }
    },
    [proceedEnabled, loading, validations]
  );

  useEffect(
    () => {
      document.title = `Beam - Client summary`;
      if (participantCopy && validationsCopy) {
        dispatch(resetParticipantData());
      } else if (participant && validations) {
        initData(participant, validations);
      }
    },
    [participant, validations]
  );

  const unsuccessfulEntries = data.filter(
    employer =>
      employer.validations.length > 0 ||
      employer.defaultFunds == null ||
      employer.defaultFunds.length === 0
  );

  const successfulEntries = data.filter(
    employer =>
      employer.validations.length === 0 &&
      employer.defaultFunds &&
      employer.defaultFunds.length > 0
  );

  return (
    <div>
      <section className="Section Section--leading">
        <h1>Client summary</h1>
        <p>
          Please check your information summary. To confirm the details are
          correct, click proceed.
        </p>
      </section>
      <section className="Seciton Section--hints">
        <div>
          {unsuccessfulEntries.length > 0 &&
            successfulEntries.length > 0 && (
              <React.Fragment>
                <h3>You have provided {data.length} entries.</h3>
                <h3>
                  {unsuccessfulEntries.length} unsuccessful entries found.{' '}
                  {successfulEntries.length} sucessful entries found.
                </h3>
              </React.Fragment>
            )}
          {unsuccessfulEntries.length === 0 &&
            successfulEntries.length > 0 && (
              <h3>You have provided {data.length} successful entries.</h3>
            )}
          {successfulEntries.length === 0 &&
            unsuccessfulEntries.length > 0 && (
              <h3>You have provided {data.length} unsuccessful entries.</h3>
            )}
        </div>
        <div>
          <Callout position="bottom">
            <p>
              To change bank account details or default funds please click
              "Edit"
            </p>
          </Callout>
        </div>
      </section>
      {unsuccessfulEntries.length > 0 && (
        <section className="Section">
          <EntryTable
            caption={`${unsuccessfulEntries.length} unsuccessful ${
              unsuccessfulEntries.length > 1 ? 'entries' : 'entry'
            }`}
          >
            {unsuccessfulEntries.map(entry => (
              <ClientEntry
                key={entry.clientEmployerNumber}
                entry={entry}
                fundNameReference={fundNameReference}
                onEdit={openClientDetails}
              />
            ))}
          </EntryTable>
        </section>
      )}
      {successfulEntries.length > 0 && (
        <React.Fragment>
          <hr />
          <section className="Section">
            <EntryTable
              caption={`${successfulEntries.length} successful ${
                successfulEntries.length > 1 ? 'entries' : 'entry'
              }`}
            >
              {successfulEntries.map(entry => (
                <ClientEntry
                  key={entry.clientEmployerNumber}
                  entry={entry}
                  fundNameReference={fundNameReference}
                  onEdit={openClientDetails}
                />
              ))}
            </EntryTable>
          </section>
        </React.Fragment>
      )}
      <section className="Section PageNavGroup">
        <Button
          type="button"
          primary
          hollow
          customclass="Button--cancel"
          onClick={() => {
            dispatch(handleReturnToPayroll(payrollName, redirectUrl));
          }}
        >
          <span>Cancel</span>
        </Button>
        <Link
          className="Button Button--primary Button--hollow Button--prev"
          to={{
            pathname: '/payment-method',
            hash: props.location.hash
          }}
        >
          <span>Back</span>
        </Link>
        <Button
          primary
          next
          disabled={loading || successfulEntries.length === 0}
          onClick={proceedWithSuccessfulEntries}
        >
          <span>
            Proceed with {successfulEntries.length} successful{' '}
            {successfulEntries.length > 1 ? 'entries' : 'entry'}
          </span>
        </Button>
      </section>
      {selectedEmployer && (
        <ClientDetailsModal
          key={selectedEmployer.clientEmployerNumber}
          isOpen={isClientDetailsOpen}
          dismiss={closeClientDetails}
          selectedEmployer={selectedEmployer}
          updateEmployer={handleEmployerUpdate}
          usesCentralTrustAccount={true}
        />
      )}
    </div>
  );
};

export default withRouter(props => <YourEmployers {...props} />);
