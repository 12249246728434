import React, { useEffect } from 'react';
import { ART_FUND_DETAILS } from './../../utils/config';
import SelectedFundList from './SelectedFundList';
import SearchFund from './SearchFund';
import { useSelector, useDispatch } from 'react-redux';
import SelectSunsuperAsDefault from './SelectSunsuperAsDefault';
import {
  setARTSelected,
  setSelectedFunds
} from '../../store/actions/globalActions';

const SelectContainer = props => {
  const dispatch = useDispatch();
  const { isBureau } = useSelector(state => state.participant.data);
  const { isARTSelected, funds } = useSelector(state => state.global.data);
  const { onSelectUpdate } = props;

  const toggleFundFromSelected = fund => {
    const selected = funds;
    const selectedUSI = funds
      .sort((a, b) => {
        return (
          a.uniqueSuperannuationIdentifier - b.uniqueSuperannuationIdentifier
        );
      })
      .map(selectedFund => selectedFund.uniqueSuperannuationIdentifier);
    const index = selectedUSI.indexOf(fund.uniqueSuperannuationIdentifier);

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(fund);
    }

    const hasARTSelected =
      selected
        .map(selectedFund => selectedFund.uniqueSuperannuationIdentifier)
        .indexOf(ART_FUND_DETAILS.uniqueSuperannuationIdentifier) > -1;

    dispatch(setSelectedFunds(selected));
    dispatch(setARTSelected(hasARTSelected));
    if (onSelectUpdate) {
      onSelectUpdate();
    }
  };

  const handleRemove = usi => {
    const fund = funds.filter(
      fund => fund.uniqueSuperannuationIdentifier === usi
    )[0];
    toggleFundFromSelected(fund);
  };

  return (
    <section className="Section SelectContainer">
      {funds.length > 0 && (
        <div className="row">
          <div className="col-sm-7">
            <SelectedFundList selected={funds} onRemove={handleRemove} />
          </div>

          <div className="col-sm-5">
            <div className="ReverseCallout">
              <h3>
                {isARTSelected
                  ? isBureau
                    ? 'Make sure your employer client’s details are registered with Australian Retirement Trust.'
                    : 'We’ll create an Australian Retirement Trust Employer account for you if you don’t already have one.'
                  : 'You can add more than one default fund'}
              </h3>
            </div>
          </div>
        </div>
      )}

      {funds.length === 0 && (
        <React.Fragment>
          <h2>
            {isBureau
              ? 'Would you like to make Australian Retirement Trust the default fund for this employer client?'
              : 'Would you like to make Australian Retirement Trust your default fund?'}
          </h2>
          <SelectSunsuperAsDefault onFundToggle={toggleFundFromSelected} />
          <div
            style={{
              margin: '3rem 0',
              textAlign: 'center',
              fontWeight: '700'
            }}
          >
            {' '}
            - Or -{' '}
          </div>
          <h2>No, I'd prefer to search for a different default fund.</h2>
        </React.Fragment>
      )}

      <SearchFund selected={funds} onFundToggle={toggleFundFromSelected} />
    </section>
  );
};

export default SelectContainer;
