import React, { useState, useEffect } from 'react';
import BankAccountSection from './BankAccountSection';
import { getDateInYYYYMMDDFormat } from './../../utils/date';
import { DEFAULT_COUNTRY_CODE } from './../../utils/validationConstants';

const BankAccountSectionContainer = props => {
  const [data, setData] = useState({
    // Initialise a blank account entity for new account
    account: {
      bsb: '',
      accountName: '',
      accountNumber: ''
    }
  });

  // Update the temporary form data within current scope without pushing up to parent,
  // leave the addtion of the valid account details to handleSectionChange function
  const handleNewAccountChange = newData => {
    setData({ ...newData });
  };

  const handleSectionChange = newData => {
    const { directDebitAuthorityId, onChange, index } = props;
    // Populate certain properties in the authority with the BSB information
    const {
      financialInstitutionCode,
      bsbAddress,
      postcode,
      state,
      suburb
    } = newData.bsbInfo;

    onChange(index, {
      directDebitAuthorityId: directDebitAuthorityId,
      // Pick up the latest account details from the form if it is an existing authority; otherwise get the data from current state
      account: props.index != null ? newData.account : data.account,
      address: {
        addressType: 'RESIDENTIAL',
        countryCode: DEFAULT_COUNTRY_CODE,
        line1: bsbAddress,
        line2: '',
        line3: '',
        line4: '',
        postCode: postcode,
        state,
        suburb
      },
      // Pass back the index (temporary identifer) and the businessIdentifier
      index: index,
      businessIdentifier: props.businessIdentifier,
      organisationName: financialInstitutionCode,
      startDate: getDateInYYYYMMDDFormat(),
      endDate: null
    });
  };

  return props.index != null ? (
    <BankAccountSection
      key={props.index}
      {...props}
      onChange={handleSectionChange}
    />
  ) : (
    <BankAccountSection
      key={'new'}
      {...data}
      {...props}
      onChange={handleNewAccountChange}
      onSave={handleSectionChange}
    />
  );
};

export default BankAccountSectionContainer;
