import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import BusinessDetailsSection from './../../components/Fieldsets/BusinessDetailsSection';
import ContactDetailsSection from './../../components/Fieldsets/ContactDetailsSection';
import PaymentOptions from './../PaymentMethod/PaymentOptions';
import ValidationPanel from '../../components/ValidationPanel/ValidationPanel';
import './ClientDetailsModal.css';
import Button from '../../components/Button/Button';
import set from 'lodash/set';
import get from 'lodash/get';
import DefaultFundSeciton from '../../components/Fieldsets/DefaultFundSeciton';
import PaymentSelection from '../PaymentMethod/PaymentSelection';
import PaymentSelectionDisplayContainer from '../PaymentMethod/PaymentSelectionDisplayContainer';
import { isActive } from '../../utils/date';
import { setNextEnabled } from '../../store/actions/globalActions';

Modal.setAppElement('#root');

const ClientDetailsModal = ({
  updateEmployer,
  selectedEmployer,
  dismiss,
  isOpen
}) => {
  const dispatch = useDispatch();
  const [employer, setEmployer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const { participant } = useSelector(state => state.participant.data);
  const { nextEnabled } = useSelector(state => state.global.data);

  const handleSectionChange = newData => {
    setEmployer({ ...employer, ...newData });
    //updateEmployer({ ...employer, ...newData });
  };

  const handleSubmit = event => {
    setSubmitted(true);
    event.preventDefault();
    updateEmployer(employer);
  };

  const initFormData = () => {
    if (get(selectedEmployer, 'businessIdentifier.value') != null) {
      set(
        selectedEmployer,
        'businessIdentifier.value',
        get(selectedEmployer, 'businessIdentifier.value').replace(/\s/g, '')
      );
    }

    if (get(selectedEmployer, 'contact.phone.number') != null) {
      set(
        selectedEmployer,
        'contact.phone.number',
        get(selectedEmployer, 'contact.phone.number').replace(/\s/g, '')
      );
    }

    let emp = { ...selectedEmployer };
    if (selectedEmployer.contact == null) {
      emp.contact = {
        title: '',
        firstName: '',
        familyName: '',
        email: '',
        phone: {
          phoneNumberType: '',
          number: '',
          areaCode: ''
        }
      };
    }

    setEmployer(emp);

    // Navigate to next page when page-level nav is allowed and no page-level validtions are present
    if (
      submitted &&
      nextEnabled &&
      selectedEmployer.validations.length === 0 &&
      selectedEmployer.defaultFunds &&
      selectedEmployer.defaultFunds.length > 0
    ) {
      setSubmitted(false);
      dismiss();
    }
  };

  useEffect(() => {
    initFormData();
    dispatch(setNextEnabled(false));
  }, []);

  const defaultAuthority = participant.directDebitAuthorities
    .map((authority, index) => ({
      ...authority,
      index
    }))
    .filter(
      authority =>
        authority.employers &&
        authority.employers.length === 0 &&
        authority.endDate === null &&
        isActive(authority.endDate)
    );

  const usesCentralTrustAccount =
    defaultAuthority !== 'undefined' && defaultAuthority.length !== 0
      ? 'true'
      : 'false';

  return (
    <Modal
      className={{
        base: 'modal-content',
        beforeClose: 'modal-content--before-close',
        afterOpen: 'modal-content--after-open'
      }}
      overlayClassName={{
        base: 'modal-overlay',
        beforeClose: 'modal-overlay--before-close',
        afterOpen: 'modal-overlay--after-open'
      }}
      isOpen={!!isOpen}
      closeTimeoutMS={250}
      onRequestClose={dismiss}
      contentLabel="Update Details"
    >
      <div className="modal-header">
        <h1>Update details</h1>
        <Button customclass="modal-close" onClick={dismiss}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </Button>
      </div>
      <div className="modal-body">
        {employer && (
          <form>
            {!nextEnabled &&
              employer.validations &&
              employer.validations.length > 0 && (
                <ValidationPanel errors={employer.validations} />
              )}
            <BusinessDetailsSection
              businessIdentifier={employer.businessIdentifier}
              organisationName={employer.organisationName}
              validations={
                employer.validations &&
                employer.validations.filter(
                  error =>
                    error.path.indexOf('businessIdentifier') > -1 ||
                    error.path.indexOf('organisationName') > -1
                )
              }
              onChange={handleSectionChange}
              isEmployer={true}
            />
            <ContactDetailsSection
              contact={employer.contact}
              validations={
                employer.validations &&
                employer.validations.filter(
                  error => error.path.indexOf('contact') > -1
                )
              }
              onChange={handleSectionChange}
            />
            <fieldset className="FormSection">
              <div className="row">
                <div className="col-sm-4 col-md-3">
                  <legend>Ways to pay</legend>
                </div>
                <div className="col-sm-8 col-md-9">
                  <PaymentOptions isEmployer={true} />
                  {usesCentralTrustAccount === 'true' ? (
                    <PaymentSelectionDisplayContainer />
                  ) : (
                    <PaymentSelection
                      isEmployer={true}
                      businessIdentifier={employer.businessIdentifier}
                      clientEmployerNumber={employer.clientEmployerNumber}
                    />
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset className="FormSection">
              <div className="row">
                <div className="col-sm-4 col-md-3">
                  <legend>Default fund</legend>
                </div>
                <div className="col-sm-8 col-md-9">
                  <DefaultFundSeciton
                    employer={employer}
                    onChange={handleSectionChange}
                  />
                </div>
              </div>
            </fieldset>
            {nextEnabled &&
              employer.validations &&
              employer.validations.length > 0 && (
                <ValidationPanel errors={employer.validations} />
              )}
            <section className="Section PageNavGroup">
              <Button primary type="submit" onClick={handleSubmit}>
                Update employer client details
              </Button>
            </section>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ClientDetailsModal;
