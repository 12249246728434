import {
  CLEAR_PARTICIPANT,
  SAVE_PARTICIPANT_DATA,
  RESET_PARTICIPANT_DATA,
  GET_PARTICIPANT,
  GET_PARTICIPANT_FAILURE,
  GET_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_FAILURE,
  UPDATE_PARTICIPANT_SUCCESS
} from '../actions/participantActions';

const initialState = {
  data: {},
  error: null
};

const participantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT:
    case UPDATE_PARTICIPANT:
      return {
        ...state,
        data: { ...state.data, loading: true },
        error: null
      };
    case GET_PARTICIPANT_SUCCESS:
    case UPDATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        error: null
      };
    case GET_PARTICIPANT_FAILURE:
    case UPDATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        data: { ...state.data, loading: false },
        error: action.payload
      };
    case CLEAR_PARTICIPANT:
      return {
        ...state,
        data: { loading: false },
        error: null
      };
    case SAVE_PARTICIPANT_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    case RESET_PARTICIPANT_DATA:
      return {
        ...state,
        data: resetFromDataCopy(state.data)
      };
    default:
      return state;
  }
};

const resetFromDataCopy = data => {
  return {
    ...data,
    participant: data.participantCopy || null,
    validations: data.validationsCopy || null,
    participantCopy: null,
    validationsCopy: null
  };
};

export { participantReducer };
