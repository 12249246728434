import React from 'react';
import { handleError } from '../../utils/error';
import Loader from './../../components/Loader/Loader';
import ClearningHouseApi from '../../store/api/clearningHouse';

function withAcceptance(WrappedComponent, name, url, title, acceptance) {
  class WithAcceptance extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        terms: {}
      };
    }

    componentDidMount() {
      ClearningHouseApi.getText(url)
        .then(terms => {
          console.log(terms);
          this.setState({ terms });
        })
        .catch(err => handleError(err));
    }

    render() {
      console.log(this.state);
      return this.state.terms.length > 0 ? (
        <WrappedComponent
          name={name}
          url={url}
          title={title}
          acceptance={acceptance}
          terms={this.state.terms}
          {...this.props}
        />
      ) : (
        <Loader />
      );
    }
  }

  WithAcceptance.displayName = `WithAcceptance(${getDisplayName(
    WrappedComponent
  )})`;

  return WithAcceptance;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withAcceptance;
