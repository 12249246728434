export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const EMAIL_MAX_LENGTH = 250;
export const NAME_REGEX = /^[0-9a-zA-Z \.,\?!\(\)\{\}:;'\|\-_=\\\/@#$%\*=&"]+$/; // eslint-disable-line no-useless-escape
export const NAME_MAX_LENGTH = 40;
export const ALPHA_NUM_SPACE_REGEX = /[0-9a-zA-Z ]+/;
export const ACCOUNT_NAME_REGEX = /^[A-Za-z0-9+\-@ $!%&()*./#=:;?'[\]_^]+$/;
export const BETWEEN_SPACE_TILDE_REGEX = /[\x20-\x7E]+/;
export const ORG_NAME_MAX_LENGTH = 200;
export const FUND_NAME_MAX_LENGTH = 200;
export const TITLES = [
  '2LT',
  'AB',
  'ABBOTT',
  'AC',
  'ACM',
  'ACW',
  'ADML',
  'AIRCDRE',
  'ALD',
  'AM',
  'AMBSR',
  'ARCHBISHOP',
  'ARCHDEACON',
  'ASSOC PROF',
  'AVM',
  'BARON',
  'BARONESS',
  'BDR',
  'BISHOP',
  'BR',
  'BRIG',
  'CANON',
  'CAPT',
  'CARDNL',
  'CDRE',
  'CDT',
  'CHAP',
  'CMDR',
  'CMM',
  'COL',
  'CONSUL',
  'COUNT',
  'COUNTESS',
  'CPL',
  'CPO',
  'DAME',
  'DEACON',
  'DEACONESS',
  'DEAN',
  'DEPUTY SUPT',
  'DIRECTOR',
  'DR',
  'EARL',
  'ENGR',
  'FLGOFF',
  'FLTLT',
  'FR',
  'FSGT',
  'GEN',
  'GP CAPT',
  'HON',
  'JUDGE',
  'JUSTICE',
  'LAC',
  'LACW',
  'LADY',
  'LBDR',
  'LCPL',
  'LEUT',
  'LORD',
  'LS',
  'LT',
  'LTCOL',
  'LTGEN',
  'MADAM',
  'MADAME',
  'MAJ',
  'MAJGEN',
  'MAYOR',
  'MAYORESS',
  'MGR',
  'MIDN',
  'MISS',
  'MON',
  'MOST REV',
  'MR',
  'MRS',
  'MS',
  'MSTR',
  'MTHR',
  'NURSE',
  'PASTOR',
  'PLTOFF',
  'PO',
  'PROF',
  'PTE',
  'RABBI',
  'RADM',
  'RECTOR',
  'REV',
  'RT REV',
  'RTHON',
  'SBLT',
  'SCDT',
  'SEN',
  'SGT',
  'SIR',
  'SISTER SUP',
  'SMN',
  'SQNLDR',
  'SR',
  'SSGT',
  'SUPT',
  'SWAMI',
  'VADM',
  'VCE CMNDR',
  'VISCOUNT',
  'WCDR',
  'WO',
  'WO1',
  'WO2',
  'WOFF',
  'CAPT, RAN',
  'GOV',
  'LCDR',
  'LTGOV',
  'OCDT',
  'RSM',
  'RSM-A',
  'SNR',
  'SM',
  'WOFF-AF',
  'WO-N'
];
export const SUFFIX_REGEX = [
  'II',
  'III',
  'IV',
  'AC',
  'CH',
  'ESQ',
  'JNR',
  'MHA',
  'MLC',
  'MP',
  'SNR',
  'BM',
  'BEM',
  'COMDC',
  'CV',
  'DFM',
  'DSC',
  'DSM',
  'GC',
  'KB',
  'KCMG',
  'DCMG',
  'KCB',
  'DCB',
  'KBE',
  'DBE',
  'KCVO',
  'DCVO',
  'AK',
  'AD',
  'KG',
  'KT',
  'OAM',
  'MHR',
  'MLA',
  'AM',
  'MBE',
  'MC',
  'OC',
  'AO',
  'OBE',
  'OM',
  'QC',
  'SC',
  'VC'
];
export const AREA_CODE_REGEX = /^[0-9]{2}$/;
export const AREA_CODE_MAX_LENGTH = 2;

/*
 * Address
 */

export const ADDRESS_MAX_LENGTH = 50;
export const DEFAULT_COUNTRY_CODE = 'au';
export const COUNTRY_CODES = [
  'ad',
  'ae',
  'af',
  'ag',
  'ai',
  'al',
  'am',
  'ao',
  'aq',
  'ar',
  'as',
  'at',
  'au',
  'aw',
  'ax',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bm',
  'bn',
  'bo',
  'bq',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'ca',
  'cc',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cu',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ec',
  'ee',
  'eg',
  'eh',
  'er',
  'es',
  'et',
  'fi',
  'fj',
  'fk',
  'fm',
  'fo',
  'fr',
  'ga',
  'gb',
  'gd',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hm',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'il',
  'im',
  'in',
  'io',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'nf',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nu',
  'nz',
  'om',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pn',
  'pr',
  'ps',
  'pt',
  'pw',
  'py',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'sh',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'ss',
  'st',
  'sv',
  'sx',
  'sy',
  'sz',
  'tc',
  'td',
  'tf',
  'tg',
  'th',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tw',
  'tz',
  'ua',
  'ug',
  'um',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vg',
  'vi',
  'vn',
  'vu',
  'wf',
  'ws',
  'ye',
  'yt',
  'za',
  'zm',
  'zw'
];
export const POSTCODE_REGEX = /^[0-9]{4}$/;
export const STATES = [
  'ACT',
  'NSW',
  'NT',
  'TAS',
  'AAT',
  'QLD',
  'SA',
  'VIC',
  'WA'
];

/*
 * Account
 */

export const ACCOUNT_BSB_REGEX = /^[0-9]{6}$/;
export const ACCOUNT_NUMBER_REGEX = /^[0-9]{1,9}$/;
export const ACCOUNT_NUMBER_MAX_LENGTH = 9;
