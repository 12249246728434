import React, { useEffect, useState } from 'react';
import { ART_FUND_DETAILS } from './../../utils/config';
import { getDateInYYYYMMDDFormat } from './../../utils/date';
import { isActive } from './../../utils/date';
import SelectContainer from './SelectContainer';
import difference from 'lodash/difference';
import SearchApi from '../../store/api/search';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedFunds,
  setARTSelected
} from '../../store/actions/globalActions';

const DefaultFundSection = props => {
  //const [nascentUSI, setNascentUSI] = useState([]);
  const [previousSaved, setPreviousSaved] = useState([]);
  const { funds } = useSelector(state => state.global.data);
  const { employer } = props;
  const dispatch = useDispatch();

  const handleSelectionChange = () => {
    const selected = funds;
    // Adding and removing updated set and navigate to T&C page
    const oldSet = previousSaved.map(fund => fund.usi);
    const newSet = selected.map(fund => fund.uniqueSuperannuationIdentifier);

    const newData = newSet.map(usi => ({
      endDate: null,
      startDate: getDateInYYYYMMDDFormat(),
      usi: usi
    }));

    const removed = difference(oldSet, newSet)
      .map(usi => {
        return previousSaved.find(fund => fund.usi === usi);
      })
      .map(fund => {
        return {
          ...fund,
          endDate: getDateInYYYYMMDDFormat()
        };
      });
    props.onChange({ ...employer, defaultFunds: [...newData, ...removed] });
  };

  const filterByEndDate = funds => {
    return funds.filter(fund => isActive(fund.endDate));
  };

  const getAllDefaultFundDetails = async activeFunds => {
    try {
      let fundDetails = [];
      let promises = activeFunds.map(async fund => {
        return SearchApi.getFundDetails('usi', fund.usi);
      });
      let res = await Promise.all(promises);
      res.forEach(response => {
        if (response[0]) {
          fundDetails.push(response[0]);
        }
      });
      dispatch(setSelectedFunds(fundDetails));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(
    () => {
      dispatch(setSelectedFunds([]));
      dispatch(setARTSelected(false));
      const { defaultFunds } = employer;
      if (defaultFunds && defaultFunds.length > 0) {
        // Ignore inactive items
        const activeFunds = filterByEndDate(defaultFunds);
        const previousSaved = activeFunds.filter(
          fund => fund.defaultFundId != null
        );
        setPreviousSaved(previousSaved);
        getAllDefaultFundDetails(activeFunds);
      }
    },
    [employer]
  );

  return (
    <div>
      <SelectContainer onSelectUpdate={handleSelectionChange} />
    </div>
  );
};

export default DefaultFundSection;
