import React, { Component } from 'react';
import logo from './beam-logo.svg';
import Progress from './../Progress/Progress';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <img src={logo} className="Header-logo" alt="logo" />
        <Progress />
      </header>
    );
  }
}

export default Header;
