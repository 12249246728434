import React from 'react';

function DefaultFundHeader() {
  return (
    <div>
      <h1>Save time by letting us know your default fund</h1>
      <p>
        Telling us the default fund you are a registered employer with now will
        save you time in the future. You'll be able to automatically create new
        member accounts when you need to join new employees.
      </p>
    </div>
  );
}

export default DefaultFundHeader;
