import React from 'react';
import './FormGroup.css';

function FormGroup(props) {
  const { hidden, label, required, hint, name, id, type, value, error } = props;
  return (
    <div className="FormGroup" hidden={hidden}>
      <label htmlFor={id}>
        {label} {required && <span className="Form-required">*</span>}
        {hint && <span className="Form-hint">{hint}</span>}
      </label>
      <input
        name={name}
        id={id}
        type={type || 'text'}
        value={value}
        required={required}
        {...props}
        className={error && 'has-error'}
      />
      {error && error.length > 0 && <div className="Form-error">{error}</div>}
    </div>
  );
}

export default FormGroup;
