import './Button.css';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Button extends React.PureComponent {
  render() {
    const {
      primary,
      secondary,
      prev,
      next,
      hollow,
      customclass,
      ...rest
    } = this.props;

    const btnClass = classNames({
      Button: true,
      'Button--primary': primary,
      'Button--secondary': secondary,
      'Button--hollow': hollow,
      'Button--prev': prev,
      'Button--next': next,
      [customclass]: customclass
    });

    return <button className={btnClass} {...rest} />;
  }
}

Button.propTypes = {
  color: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  prev: PropTypes.bool,
  next: PropTypes.bool,
  hollow: PropTypes.bool
};

Button.defaultProps = {
  primary: false,
  secondary: false,
  prev: false,
  next: false,
  hollow: false
};

export default Button;
