import React, { useState, useEffect } from 'react';
import FormGroup from './../../components/FormGroup/FormGroup';
import Button from './../../components/Button/Button';
import {
  ACCOUNT_BSB_REGEX,
  ACCOUNT_NUMBER_REGEX,
  ACCOUNT_NAME_REGEX
} from './../../utils/validationConstants';
import values from 'lodash/values';
import { useSelector } from 'react-redux';
import SearchApi from '../../store/api/search';

const INITIAL_BSBINFO = {
  financialInstitutionCode: '',
  bsbAddress: '',
  postcode: '',
  state: '',
  suburb: ''
};

const INITIAL_ERROR = {
  bsb: '',
  accountNumber: '',
  accountName: ''
};

const BankAccountSeciton = props => {
  const { onlyAcceptDirectDebit } = useSelector(state => state.payment.data);
  const [bsbInfo, setBsbInfo] = useState(INITIAL_BSBINFO);
  const [error, setError] = useState(INITIAL_ERROR);
  const {
    directDebitAuthorityId,
    account,
    validations,
    onRemove,
    onReset,
    onSave,
    onChange
  } = props;

  const handleSaveNewAccount = () => {
    onSave({
      ...props,
      bsbInfo: bsbInfo
    });
  };

  const handleInputChange = event => {
    let { name, value } = event.target;

    // For existing account, bubble up all associate properties as they are already set;
    // for new account, only propagates the changed property
    if (props.index != null) {
      onChange({
        ...props,
        account: {
          ...props.account,
          [name]: value
        },
        bsbInfo: bsbInfo
      });
    } else {
      onChange({
        account: {
          ...props.account,
          [name]: value
        }
      });
    }
  };

  const handleInputBlur = event => {
    // Validate input field on blur event
    const { name, required } = event.target;
    let { value } = event.target;

    let errors = { ...error };

    // Set error message to "Required" when mandatory field is empty
    if (!value && required) {
      errors[name] = 'Required';
    } else if (value) {
      switch (name) {
        case 'bsb':
          if (!ACCOUNT_BSB_REGEX.test(value)) {
            errors.bsb = 'Please enter a valid 6-digit BSB number.';
            setBsbInfo(INITIAL_BSBINFO);
          } else {
            lookUpBSBInfo(value);
          }
          break;
        case 'accountNumber':
          if (!ACCOUNT_NUMBER_REGEX.test(value)) {
            errors.accountNumber = 'Please enter a valid account number.';
          }
          break;
        case 'accountName':
          if (!ACCOUNT_NAME_REGEX.test(value)) {
            errors.accountName = `Account name can only contain space, alpha numeric characters, plus the following special characters +, -, @, $, !, %, &, (, ), *, ., /, #, =, :, ;, ?, ’, [, ], _, ^`;
          }
          break;
        default:
          break;
      }
    }
    setError(errors);
  };

  const handleInputFocus = event => {
    const { name } = event.target;

    // Remove error message when user starts modifying field
    setError({
      ...error,
      [name]: ''
    });
  };

  const lookUpBSBInfo = bsb => {
    let errorMsg = '';

    SearchApi.getBsbInfo(bsb)
      .then(bsbInfo => {
        setError({ ...error, bsb: errorMsg });
        setBsbInfo(bsbInfo);
        if (props.index != null) {
          props.onChange({
            ...this.props,
            bsbInfo: bsbInfo
          });
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
            errorMsg = 'Please enter a valid 6-digit BSB number.';
          } else {
            errorMsg =
              'No matching branch found. Double check your BSB number.';
          }
        }
        setBsbInfo(INITIAL_BSBINFO);
        setError({
          ...error,
          bsb: errorMsg
        });
      });
  };

  const prepopulateValiationErrors = validations => {
    if (validations && validations.length) {
      let errors = null;
      validations.forEach(validation => {
        // Errors related to bsb
        if (
          validation.path.includes('bsb') ||
          validation.path.includes('organisationName') ||
          validation.path.includes('address')
        ) {
          errors = {
            ...error,
            bsb: 'No matching branch found. Double check your BSB number.'
          };
        } else if (validation.path.includes('account')) {
          errors = {
            ...error,
            [validation.path.split('account.')[1]]: validation.message
          };
        }
      });
      setError(errors);
    } else {
      setError(INITIAL_ERROR);
    }
  };

  const prepopulateBsbInfo = () => {
    // Pre-populate when bsb info is available
    if (props.organisationName != null) {
      setBsbInfo({
        financialInstitutionCode: props.organisationName,
        bsbAddress: (props.address && props.address.line1) || '',
        suburb: (props.address && props.address.suburb) || '',
        state: (props.address && props.address.state) || '',
        postcode: (props.address && props.address.postCode) || ''
      });
    } else {
      setBsbInfo(INITIAL_BSBINFO);
    }
  };

  useEffect(() => {
    if (
      props.index != null ||
      (props.validations && props.validations.length > 0)
    ) {
      prepopulateValiationErrors(props.validations);
    }
  }, [props.validations, props.account, props.index]);

  useEffect(() => {
    if (props.index != null) {
      prepopulateBsbInfo();
    }
  }, [props.index]);

  useEffect(() => {
    prepopulateBsbInfo();
  }, []);

  const { bsb, accountNumber, accountName } = account;

  const dataInvalid =
    bsb.length === 0 ||
    accountNumber.length === 0 ||
    accountName.length === 0 ||
    values(error).some(value => value !== '') ||
    values(bsbInfo).some(value => value == null || value === '') ||
    (validations && validations.length > 0);

  const eventHandlers = {
    onChange: handleInputChange,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur
  };

  return (
    <fieldset className="FormSection">
      <div className="row">
        <div className="col-sm-6">
          <FormGroup
            name="bsb"
            id="bsb"
            label="BSB"
            required={onlyAcceptDirectDebit ? true : false}
            value={bsb}
            error={error.bsb}
            readOnly={directDebitAuthorityId && directDebitAuthorityId !== '0'}
            {...eventHandlers}
          />
          {bsbInfo.financialInstitutionCode.length > 0 && (
            <div className="bsbInfo">
              {bsbInfo.financialInstitutionCode} - {bsbInfo.bsbAddress},{' '}
              {bsbInfo.suburb}
            </div>
          )}
        </div>
        <div className="col-sm-6">
          <FormGroup
            name="accountNumber"
            id="accountNumber"
            label="Account Number"
            required={onlyAcceptDirectDebit ? true : false}
            value={accountNumber}
            error={error.accountNumber}
            readOnly={directDebitAuthorityId && directDebitAuthorityId !== '0'}
            {...eventHandlers}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <FormGroup
            name="accountName"
            id="accountName"
            label="Account Name"
            required={onlyAcceptDirectDebit ? true : false}
            hint="Letters, numerals, spaces plus the following special characters are allowed: +, -, @, $, !, %, &, (, ), *, ., /, #, =, :, ;, ?, ’, [, ], _, ^"
            value={accountName}
            error={error.accountName}
            readOnly={directDebitAuthorityId && directDebitAuthorityId !== '0'}
            {...eventHandlers}
          />
        </div>
      </div>
      <div className="Form-buttongroup">
        {props.index != null && (
          <Button type="button" primary hollow onClick={onRemove}>
            Remove
          </Button>
        )}
        {!directDebitAuthorityId && (
          <Button
            type="button"
            primary
            disabled={dataInvalid}
            onClick={props.index != null ? onReset : handleSaveNewAccount}
          >
            <span>Save</span>
          </Button>
        )}
      </div>
    </fieldset>
  );
};

export default props => <BankAccountSeciton {...props} />;
