import React from 'react';
import Accordion from './../../components/Accordion/Accordion';

function WhySelectDefaultFund() {
  return (
    <Accordion
      triggerId="trigger-1"
      panelId="panel-1"
      title="What is a default fund?"
      expanded={false}
    >
      <section className="Accrodion-section">
        <p>
          Most employees have the right to choose which super fund will receive
          their SG contributions.
        </p>
        <p>
        Before you offer your employee the option to choose a super fund, you must nominate a fund that you will pay their super into if they can't or don't choose their own fund, or don't have a stapled fund.  This fund is your company's default fund.
        </p>
        <p>The super fund you select as your default fund must:</p>
        <ul>
          <li>
            Be a complying fund (one that meets specifc requirements and
            obligations under super law)
          </li>
          <li>
            Be authorised by the Australian Prudential Regulation Authority
            (APRA) to offer a MySuper product.
          </li>
        </ul>
      </section>
      <section className="Accrodion-section">
        <h4>How to select a default fund</h4>
        <p>
          Consider which fund would best meet the needs of your employees. Make
          sure you read the Product Disclosure Statement. The default fund you
          choose will normally require you to register as a participating
          employer with them.
        </p>
      </section>
    </Accordion>
  );
}

export default WhySelectDefaultFund;
