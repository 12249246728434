import {
  SET_EMPLOYER_REGISTER_SUPER,
  SET_NEXT_ENABLED,
  SET_ART_SELECTED,
  SET_SELECTED_FUNDS
} from '../actions/globalActions';

const initialState = {
  data: {
    nextEnabled: false,
    employerRegisterSuper: false,
    isARTSelected: false,
    funds: []
  }
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYER_REGISTER_SUPER:
    case SET_NEXT_ENABLED:
    case SET_ART_SELECTED:
    case SET_SELECTED_FUNDS:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
};

export { globalReducer };
