import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PaymentOptions = props => {
  const { isBureau } = useSelector(state => state.participant.data);
  const { paymentOptions, onlyAcceptDirectDebit } = useSelector(
    state => state.payment.data
  );

  const convertPaymentOptionToLabel = option => {
    let label = '';
    switch (option) {
      case 'DIRECT_DEBIT':
        label = 'Direct Debit';
        break;
      case 'DIRECT_CREDIT':
        label = 'EFT';
        break;
      case 'PASS_THROUGH':
        label = 'Direct to Fund';
        break;
      default:
        label = option;
        break;
    }

    return label;
  };

  return (
    <section className="Section PaymentOptions">
      <div className="row">
        <div className="col-sm-4 col-md-3">
          {paymentOptions.length > 0 && (
            <ul>
              {paymentOptions.map(option => {
                return (
                  <li key={option.id}>
                    {convertPaymentOptionToLabel(option.paymenttype)}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="col-sm-8 col-md-9">
          {!onlyAcceptDirectDebit && (
            <React.Fragment>
              {paymentOptions.length > 1 ? (
                <p>
                  Choose your payment method when you submit contributions.
                  Instructions on how to pay will be provided at this time.
                </p>
              ) : (
                <p>
                  You’ll be provided with payment instructions when you submit
                  {isBureau ? '' : ' your'} contributions.
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

export default props => <PaymentOptions {...props} />;
