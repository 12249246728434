import React, { Component } from 'react';
import Accordion from './../../components/Accordion/Accordion';
import { convertHyphenJoinedToCamelCase } from './../../utils/string';

class TAndCAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const value = e.target.checked;

    this.setState({ accepted: value });

    this.props.onAcceptance(
      convertHyphenJoinedToCamelCase(this.props.name),
      value
    );
  }

  createMarkup(markup) {
    return { __html: markup };
  }

  render() {
    const { name, title, terms, acceptance } = this.props;

    return (
      <div>
        <Accordion
          triggerId={`${name}-trigger`}
          panelId={`${name}-panel`}
          title={title}
          expanded={true}
          hasTick={this.state.accepted}
        >
          <div dangerouslySetInnerHTML={this.createMarkup(terms)} />
          <p>
            <input
              id={name}
              type="checkbox"
              name={name}
              onChange={this.handleChange}
            />
            <label htmlFor={name}>
              <strong>{acceptance}</strong>
            </label>
          </p>
        </Accordion>
      </div>
    );
  }
}

export default TAndCAccordion;
