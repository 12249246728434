import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FUND_SEARCH_TYPES } from './../../utils/config';
import SearchFundResults from './SearchFundResults';
import FormGroup from './../../components/FormGroup/FormGroup';
import Button from './../../components/Button/Button';
import SearchApi from '../../store/api/search';

class SearchFund extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeOptions: FUND_SEARCH_TYPES,
      type: {
        value: 'name',
        label: 'Fund name'
      },
      query: '',
      funds: [],
      isLoaded: false
    };
  }

  handleInputChange = event => {
    let { name, value } = event.target;

    // Remove all spaces for USI and ABN
    if (this.state.type.value === 'usi' || this.state.type.value === 'abn') {
      value = value.replace(/ /g, '');
    }

    this.setState({
      [name]: value,
      isLoaded: false
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmission();
    }
  };

  handleSearchTypeChange = type => {
    this.setState({ type });
  };

  handleToggle = event => {
    const { name } = event.target;

    const fund = this.state.funds.filter(
      fund => fund.uniqueSuperannuationIdentifier === name
    )[0];

    this.props.onFundToggle(fund);
  };

  handleSubmission = () => {
    this.search();
  };

  search() {
    if (!this.state.query.length) {
      return false;
    }
    this.setState({ isLoaded: false });
    SearchApi.getFundDetails(this.state.type.value, this.state.query)
      .then(funds => {
        this.setState({
          // Filter out funds that do not have USI, i.e. non APRA funds
          funds: funds.filter(fund => fund.uniqueSuperannuationIdentifier),
          isLoaded: true
        });
      })
      .catch(err => console.warn(err));
  }

  componentDidUpdate(prevProps, prevState) {
    // Redo search when search type is changed
    if (
      prevState.type.value !== this.state.type.value &&
      this.state.query.length > 0
    ) {
      this.search();
    }
  }

  render() {
    const { type, typeOptions, query, funds, isLoaded } = this.state;

    return (
      <div>
        <div>
          <div className="row">
            <div className="col-sm-4">
              <div className="FormGroup">
                <label htmlFor="search-by-type">Search by</label>
                <Select
                  name="search-by-type"
                  value={type}
                  options={typeOptions}
                  clearable={false}
                  onChange={this.handleSearchTypeChange}
                />
              </div>
            </div>
            <div className="col-sm-8">
              <FormGroup
                name="query"
                id="query"
                label="Starting with..."
                placeholder={`Search by ${type.label}`}
                value={query}
                minLength="3"
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Button type="button" primary onClick={this.handleSubmission}>
                Search
              </Button>
            </div>
          </div>
        </div>

        {isLoaded && funds.length === 0 && (
          <React.Fragment>
            {query.length < 3 ? (
              <p className="Text--error">
                Please provide more than 3 characters in your search query
              </p>
            ) : (
              <p className="Text--error">
                Your search by {type.label.toLowerCase()}{' '}
                <strong>"{query}"</strong> did not match any fund.
                <br />
                Please check your search and try again.
              </p>
            )}
          </React.Fragment>
        )}
        {isLoaded && funds.length > 0 && (
          <SearchFundResults
            funds={funds}
            selected={this.props.selected}
            onFundToggle={this.handleToggle}
          />
        )}
      </div>
    );
  }
}

SearchFund.propTypes = {
  selected: PropTypes.array.isRequired,
  onFundToggle: PropTypes.func.isRequired
};

export default SearchFund;
