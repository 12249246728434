import './utils/polyfill';
import 'what-input';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css/normalize.css';
import 'react-select/dist/react-select.css';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { rootReducer } from './store/reducer';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';

// Initialise GTM Module based on environment
const { hostname } = window.location;
const environment = hostname.split('.')[0];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

switch (environment) {
  case 'participant-intg':
  case 'participant-acpt':
    const tagManagerArgs = {
      gtmId: 'GTM-WK53MNJ'
    };
    TagManager.initialize(tagManagerArgs);
    break;

  default:
    break;
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
