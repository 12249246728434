import {
  getPaymentOptions,
  getPaymentOptionsFailure,
  getPaymentOptionsSuccess
} from '../actions/paymentActions';
import ChannelApi from '../api/channel';

const checkIfAcceptDirectDebit = options => {
  return options.indexOf('DIRECT_DEBIT') > -1;
};
const checkIfOnlyAcceptDirectDebit = options => {
  return checkIfAcceptDirectDebit(options) && options.length === 1;
};

export const fetchPaymentOptions = () => {
  return async dispatch => {
    dispatch(getPaymentOptions());
    try {
      const paymentOptions = await ChannelApi.getPaymentOptions();
      const options = paymentOptions.map(option => option.paymenttype);
      const acceptDirectDebit = checkIfAcceptDirectDebit(options);
      const onlyAcceptDirectDebit = checkIfOnlyAcceptDirectDebit(options);
      dispatch(
        getPaymentOptionsSuccess({
          paymentOptions,
          acceptDirectDebit,
          onlyAcceptDirectDebit
        })
      );
    } catch (error) {
      dispatch(getPaymentOptionsFailure(error));
    }
  };
};
