import React, { Component } from 'react';
import Select from 'react-select';
import FormGroup from './../FormGroup/FormGroup';
import {
  ADDRESS_MAX_LENGTH,
  STATES,
  POSTCODE_REGEX,
  COUNTRY_CODES,
  DEFAULT_COUNTRY_CODE
} from './../../utils/validationConstants';
import { ADDRESS_TYPES } from './../../utils/config';

// Convert an array of options to React-Select compliant format
function convertArrayToSelectOptions(array) {
  return array.map(item => ({
    value: item,
    label: item
  }));
}

const Address_Line_Hint =
  '50 or less printable characters allowed (letters, digits and punctuation marks)';

class AddressDetailsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        addressType: '',
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        suburb: '',
        state: '',
        postCode: '',
        countryCode: ''
      }
    };
  }

  handleInputChange = event => {
    const { name, value } = event.target;

    this.props.onChange({
      address: {
        ...this.props.address,
        [name]: value
      }
    });
  };

  handleTypeSelectChange = (name, option) => {
    this.setState(prevState => {
      return {
        error: { ...prevState.error, [name]: '' }
      };
    });
    this.props.onChange({
      address: {
        ...this.props.address,
        [name]: option.value
      }
    });
  };

  handleInputBlur = event => {
    // Validate input field on blur event
    const { name, value, required } = event.target;

    let error = { ...this.state.error };

    // Set error message to "Required" when mandatory field is empty
    if (!value && required) {
      error[name] = 'Required';
    } else if (value && name === 'postCode') {
      error.postCode =
        this.props.address.countryCode !== DEFAULT_COUNTRY_CODE ||
        POSTCODE_REGEX.test(value)
          ? ''
          : 'Please enter a valid 4-digit Australian post code.';
    }
    this.setState({ error });
  };

  handleInputFocus = event => {
    const { name } = event.target;

    // Remove error message when user starts modifying field
    this.setState({
      error: {
        ...this.state.error,
        [name]: ''
      }
    });
  };

  prepopulateValiationErrors = validations => {
    let error = null;
    validations.forEach(validation => {
      if (validation.path.split('address.')[1]) {
        error = {
          ...error,
          [validation.path.split('address.')[1]]: validation.message
        };
      } else if (validation.message.includes('Post code')) {
        error = {
          ...error,
          postCode: validation.message
        };
      } else if (validation.message.includes('State')) {
        error = {
          ...error,
          state: validation.message
        };
      }
    });
    this.setState(prevState => {
      return {
        error: { ...prevState.error, ...error }
      };
    });
  };

  componentDidMount() {
    if (this.props.validations && this.props.validations.length > 0) {
      this.prepopulateValiationErrors(this.props.validations);
    }
  }

  render() {
    const address =
      this.props.address != null
        ? this.props.address
        : {
            addressType: '',
            line1: '',
            line2: '',
            line3: '',
            line4: '',
            suburb: '',
            state: '',
            postCode: '',
            countryCode: ''
          };

    const {
      addressType,
      line1,
      line2,
      line3,
      line4,
      suburb,
      state,
      postCode,
      countryCode
    } = address;

    const { error } = this.state;

    const eventHandlers = {
      onChange: this.handleInputChange,
      onFocus: this.handleInputFocus,
      onBlur: this.handleInputBlur
    };

    return (
      <fieldset className="FormSection">
        <div className="row">
          <div className="col-sm-4">
            <legend>Your business address</legend>
          </div>
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-5">
                <div className="FormGroup">
                  <label htmlFor="addressType">
                    Address Type <span className="Form-required">*</span>
                  </label>
                  <Select
                    name="addressType"
                    value={addressType || ''}
                    options={ADDRESS_TYPES}
                    required={true}
                    clearable={false}
                    onChange={this.handleTypeSelectChange.bind(
                      this,
                      'addressType'
                    )}
                    onBlur={this.handleInputBlur}
                  />
                  {error.addressType &&
                    error.addressType.length > 0 && (
                      <div className="Form-error">{error.addressType}</div>
                    )}
                </div>
              </div>
            </div>

            <FormGroup
              name="line1"
              id="line1"
              label="Address line 1"
              hint={Address_Line_Hint}
              required={true}
              maxLength={ADDRESS_MAX_LENGTH}
              value={line1 || ''}
              error={error.line1}
              {...eventHandlers}
            />

            <FormGroup
              name="line2"
              id="line2"
              label="Address line 2"
              maxLength={ADDRESS_MAX_LENGTH}
              hint={Address_Line_Hint}
              value={line2 || ''}
              error={error.line2}
              {...eventHandlers}
            />

            <FormGroup
              name="line3"
              id="line3"
              label="Address line 3"
              maxLength={ADDRESS_MAX_LENGTH}
              hint={Address_Line_Hint}
              hidden={line2 && line2.length === 0}
              value={line3 || ''}
              error={error.line3}
              {...eventHandlers}
            />

            <FormGroup
              name="line4"
              id="line4"
              label="Address line 4"
              maxLength={ADDRESS_MAX_LENGTH}
              hint={Address_Line_Hint}
              hidden={line3 && line3.length === 0}
              value={line4 || ''}
              error={error.line4}
              {...eventHandlers}
            />

            <div className="row">
              <div className="col-sm-6">
                <FormGroup
                  name="suburb"
                  id="suburb"
                  label="Suburb / Town"
                  required={true}
                  value={suburb || ''}
                  error={error.suburb}
                  {...eventHandlers}
                />
              </div>
              <div className="col-sm-6">
                {countryCode === DEFAULT_COUNTRY_CODE ? (
                  <div className="FormGroup">
                    <label htmlFor="state">
                      State / Territory <span className="Form-required">*</span>
                    </label>
                    <Select
                      name="state"
                      value={state || ''}
                      placeholder="Select a state..."
                      options={convertArrayToSelectOptions(STATES)}
                      required={true}
                      clearable={false}
                      onChange={this.handleTypeSelectChange.bind(this, 'state')}
                    />
                    {error.state &&
                      error.state.length > 0 && (
                        <div className="Form-error">{error.state}</div>
                      )}
                  </div>
                ) : (
                  <FormGroup
                    name="state"
                    id="state"
                    label="State / Territory"
                    value={state || ''}
                    error={error.state}
                    {...eventHandlers}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FormGroup
                  name="postCode"
                  id="postcode"
                  label="Postcode"
                  required={true}
                  value={postCode || ''}
                  error={error.postCode}
                  {...eventHandlers}
                />
              </div>
              <div className="col-sm-6">
                <div className="FormGroup">
                  <label htmlFor="countryCode">
                    Country code
                    <span className="Form-required">*</span>
                  </label>
                  <Select
                    name="countryCode"
                    value={countryCode || ''}
                    options={convertArrayToSelectOptions(COUNTRY_CODES)}
                    required={true}
                    clearable={false}
                    onChange={this.handleTypeSelectChange.bind(
                      this,
                      'countryCode'
                    )}
                  />
                  {error.countryCode &&
                    error.countryCode.length > 0 && (
                      <div className="Form-error">{error.countryCode}</div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default AddressDetailsSection;
