export const SET_NEXT_ENABLED = 'SET_NEXT_ENABLED';
export const SET_EMPLOYER_REGISTER_SUPER = 'SET_EMPLOYER_REGISTER_SUPER';
export const SET_ART_SELECTED = 'SET_ART_SELECTED';
export const SET_SELECTED_FUNDS = 'SET_SELECTED_FUNDS';

export const setNextEnabled = value => {
  return {
    type: SET_NEXT_ENABLED,
    payload: { nextEnabled: value }
  };
};

export const setEmployerRegisterSuper = value => {
  return {
    type: SET_EMPLOYER_REGISTER_SUPER,
    payload: { employerRegisterSuper: value }
  };
};

export const setARTSelected = value => {
  return {
    type: SET_ART_SELECTED,
    payload: { isARTSelected: value }
  };
};

export const setSelectedFunds = value => {
  return {
    type: SET_SELECTED_FUNDS,
    payload: { funds: value }
  };
};
