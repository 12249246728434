import React, { Component } from 'react';
import Accordion from '../../components/Accordion/Accordion';
import { handleError } from '../../utils/error';
import Loader from './../../components/Loader/Loader';
import ClearningHouseApi from '../../store/api/clearningHouse';

class TAndCAccordionForBulkAcceptance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: null
    };
  }

  createMarkup(markup) {
    return { __html: markup };
  }

  componentDidMount() {
    const { url } = this.props;
    ClearningHouseApi.getText(url)
      .then(terms => this.setState({ terms }))
      .catch(err => handleError(err));
  }

  render() {
    const { name, title, children, accepted } = this.props;
    const { terms } = this.state;

    if (terms == null) {
      return <Loader />;
    }

    return (
      <div>
        <Accordion
          triggerId={`${name}-trigger`}
          panelId={`${name}-panel`}
          title={title}
          expanded={true}
          hasTick={accepted}
        >
          <div dangerouslySetInnerHTML={this.createMarkup(terms)} />
          {children()}
        </Accordion>
      </div>
    );
  }
}

export default TAndCAccordionForBulkAcceptance;
