import React from 'react';
import { ART_FUND_DETAILS } from '../../utils/config';
import Button from '../Button/Button';
import ExternalLink from '../ExternalLink/ExternalLink';
import { useSelector } from 'react-redux';

const SelectSunsuperAsDefault = props => {
  const handleSelectSunsuper = () => {
    props.onFundToggle(ART_FUND_DETAILS);
  };
  const { isBureau } = useSelector(state => state.participant.data);

  return (
    <div className="Select-sunsuper">
      <div>
        <Button type="button" primary onClick={handleSelectSunsuper}>
          Select Australian Retirement Trust
        </Button>
      </div>
      <div>
        <div className="ReverseCallout">
          <div>
            <h3>
              {isBureau ? (
                <ExternalLink
                  text="Has your employer client considered Australian Retirement Trust?"
                  url="https://www.australianretirementtrust.com.au/employers/why-choose-us"
                />
              ) : (
                <ExternalLink
                  text="See why Australian Retirement Trust could be a great trust as your default fund"
                  url="https://www.australianretirementtrust.com.au/employers/why-choose-us"
                />
              )}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSunsuperAsDefault;
