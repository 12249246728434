export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const GET_PARTICIPANT_SUCCESS = 'GET_PARTICIPANT_SUCCESS';
export const GET_PARTICIPANT_FAILURE = 'GET_PARTICIPANT_FAILURE';

export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_FAILURE = 'UPDATE_PARTICIPANT_FAILURE';

export const CLEAR_PARTICIPANT = 'CLEAR_PARTICIPANT';
export const SAVE_PARTICIPANT_DATA = 'SAVE_PARTICIPANT_DATA';
export const RESET_PARTICIPANT_DATA = 'RESET_PARTICIPANT_DATA';

export const saveParticipantData = data => {
  return {
    type: SAVE_PARTICIPANT_DATA,
    payload: data
  };
};

export const resetParticipantData = () => {
  return {
    type: RESET_PARTICIPANT_DATA
  };
};

export const clearParticipant = () => {
  return {
    type: CLEAR_PARTICIPANT
  };
};

export const getParticipant = () => {
  return {
    type: GET_PARTICIPANT
  };
};

export const getParticipantSuccess = data => {
  return {
    type: GET_PARTICIPANT_SUCCESS,
    payload: data
  };
};

export const getParticipantFailure = error => {
  return {
    type: GET_PARTICIPANT_FAILURE,
    payload: error
  };
};

export const updateParticipant = () => {
  return {
    type: UPDATE_PARTICIPANT
  };
};

export const updateParticipantSuccess = data => {
  return {
    type: UPDATE_PARTICIPANT_SUCCESS,
    payload: data
  };
};

export const updateParticipantFailure = error => {
  return {
    type: UPDATE_PARTICIPANT_FAILURE,
    payload: error
  };
};
