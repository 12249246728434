import React, { useState, useEffect } from 'react';
import Callout from './../../components/Callout/Callout';
import Alert from './../../components/Alert/Alert';
import { useSelector } from 'react-redux';
import { isActive } from './../../utils/date';

const PaymentSelectionDisplayContainer = props => {
  const [data, setData] = useState([]);
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(null);
  const { participant, validations } = useSelector(
    state => state.participant.data
  );

  // Initialise the list of valid direct debit request and corresponding validations
  const initData = () => {
    // Find direct debit request validation errors at current level (participant)
    const directDebitAuthorityValidations = validations.filter(
      validation => validation.path.indexOf('directDebitAuthorities') === 0
    );

    // Extend each authority with its own validation errors
    const authorities = participant.directDebitAuthorities
      .map((authority, index) => {
        return {
          ...authority,
          index,
          validations: [
            ...directDebitAuthorityValidations.filter(error =>
              error.path.includes(`directDebitAuthorities[${index}]`)
            )
          ]
        };
      })
      .filter(
        authority =>
          authority.employers == null || authority.employers.length === 0
      );
    setData(
      authorities
        // Active authority needs to have an "endDate" that is unset or in the future
        .filter(authority => isActive(authority.endDate))
        .sort((a, b) => a.directDebitAuthorityId - b.directDebitAuthorityId)
    );
  };

  useEffect(() => {
    initData();
  }, [participant, validations]);

  return (
    <section className="Section">
      <h3>Direct Debit Requests</h3>
      {data && data.length > 0 && (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <p>
              Here are the active direct debit requests we’ve found for you.
            </p>
            <p>
              If you have more than one direct debit request listed, you can
              select which bank account to use when submitting contributions.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Callout position="right">
                <h3>
                  To remove this Central Trust Account, navigate back to
                  "Payment method" page
                </h3>
              </Callout>
            </div>
            <div className="col-md-6">
              <ul className="SelectedList">
                {data.map(authority => {
                  const valid =
                    authority.validations && authority.validations.length > 0;

                  return (
                    <li
                      key={
                        authority.directDebitAuthorityId +
                        authority.account.bsb +
                        authority.account.accountNumber
                      }
                    >
                      <span>
                        {authority.validations &&
                          authority.validations.length > 0 && <Alert />}
                      </span>
                      {authority.account.bsb} {authority.account.accountNumber}{' '}
                      ({authority.account.accountName})
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </React.Fragment>
      )}
    </section>
  );
};

export default PaymentSelectionDisplayContainer;
