import React from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { FadeTransition } from './../../components/Transition/Transition';
import Button from './../../components/Button/Button';
import Cross from './../../components/Cross/Cross';

function FundList({ children }) {
  return <ul className="FundList">{children}</ul>;
}

function SelectedFundList(props) {
  const { selected, onRemove } = props;

  return (
    <div className="SelectedList">
      <TransitionGroup component={FundList}>
        {selected.map(fund =>
          fund ? (
            <FadeTransition
              duration={150}
              key={fund.uniqueSuperannuationIdentifier}
            >
              <li className="clearfix">
                <strong>{fund.productName}</strong>
                <br />
                (USI: {fund.uniqueSuperannuationIdentifier})
                <Button
                  type="button"
                  customclass="Button--remove"
                  aria-label="Remove"
                  onClick={onRemove.bind(
                    this,
                    fund.uniqueSuperannuationIdentifier
                  )}
                >
                  <Cross />
                </Button>
              </li>
            </FadeTransition>
          ) : null
        )}
      </TransitionGroup>
    </div>
  );
}

export default SelectedFundList;
