// Global polyfills to support IE < 11
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'raf/polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import URLSearchParams from 'url-search-params'; // eslint-disable-line no-unused-vars
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

smoothscroll.polyfill();
