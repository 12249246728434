import jwt_decode from 'jwt-decode';
import axios from 'axios';
import history from './../History';

const updateToken = token => {
  try {
    const decoded = jwt_decode(token);
    const { exp, apiHostname } = decoded;

    console.log(JSON.stringify(decoded, null, 2));

    // Save token information into localStorage
    window.localStorage.setItem('access_token', token);
    window.localStorage.setItem('expires_at', exp * 1000);
    window.localStorage.setItem('api_hostname', apiHostname);

    // Update axios default setting
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${window.localStorage.getItem('access_token')}`;
    axios.defaults.baseURL = window.localStorage.getItem('api_hostname')
      ? `https://${window.localStorage.getItem('api_hostname')}`
      : '';

    // Update the access_token hash in the URL
    history.push({
      pahtname: history.location.pathname,
      hash: `access_token=${token}`
    });
  } catch (error) {
    console.log(error);
  }
};

export { updateToken };
