import React from 'react';
import './Tick.css';
import className from 'classnames';

function Tick(props) {
  const { customclass, ...rest } = props;
  const tickClasses = className({
    Tick: true,
    [customclass]: customclass
  });
  const size = props.size || 16;

  return (
    <span className={tickClasses}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size * 7 / 9}
        viewBox="0 0 36 28"
        {...rest}
      >
        <path
          fill="#ef7026"
          fillRule="evenodd"
          d="M35.3492346,7.07053054 L18.5351285,24.1339278 L15.3760252,27.3383861 C14.9577638,27.764066 14.3771186,28 13.7952433,28 C13.2158283,28 12.6351832,27.764066 12.2169218,27.3383861 L9.0565883,24.1339278 L0.649535265,15.6028533 C0.232504101,15.1771734 0,14.5879626 0,14 C0,13.4095408 0.232504101,12.8203299 0.649535265,12.3971467 L3.8086386,9.19019171 C4.22689995,8.76576014 4.80754511,8.53107445 5.38942045,8.53107445 C5.96883543,8.53107445 6.54948059,8.76576014 6.96774194,9.19019171 L13.7952433,16.1433794 L29.0310279,0.659117254 C29.4492892,0.234685689 30.0299344,0 30.6105796,0 C31.1912247,0 31.7718699,0.234685689 32.1901312,0.659117254 L35.3492346,3.86607222 C35.7674959,4.28925546 36,4.87846634 36,5.46767722 C36,6.0568881 35.7674959,6.64609897 35.3492346,7.07053054"
        />
      </svg>
    </span>
  );
}

export default Tick;
