import React, { Component } from 'react';
import Select from 'react-select';
import FormGroup from './../../components/FormGroup/FormGroup';
import {
  TITLES,
  NAME_MAX_LENGTH,
  AREA_CODE_MAX_LENGTH,
  EMAIL_REGEX
} from './../../utils/validationConstants';

// Convert an array of options to React-Select compliant format
function convertArrayToSelectOptions(array) {
  return array.map(item => ({
    value: item,
    label: item
  }));
}

class ContactDetailsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        title: '',
        firstName: '',
        familyName: '',
        email: '',
        phoneNumberType: '',
        number: '',
        areaCode: ''
      }
    };
  }

  handleInputChange = event => {
    const { name, value } = event.target;

    // Set phoneNumberType to "MOBILE" behind the scene when countryCode is set to "AU" and areaCode is "04"
    if (name === 'areaCode') {
      this.props.onChange({
        contact: {
          ...this.props.contact,
          phone: {
            ...this.props.contact.phone,
            areaCode: value,
            phoneNumberType: value === '04' ? 'MOBILE' : 'LANDLINE'
          }
        }
      });
    } else if (name === 'number') {
      this.props.onChange({
        contact: {
          ...this.props.contact,
          phone: {
            ...this.props.contact.phone,
            number: value
          }
        }
      });
    } else {
      this.props.onChange({
        contact: {
          ...this.props.contact,
          [name]: value
        }
      });
    }
  };

  handleTitleSelectChange = option => {
    this.props.onChange({
      contact: {
        ...this.props.contact,
        title: option.value
      }
    });
  };

  handleInputBlur = event => {
    // Validate input field on blur event
    const { name, value, required } = event.target;

    let error = { ...this.state.error };

    // Set error message to "Required" when mandatory field is empty
    if (!value && required) {
      error[name] = 'Required';
    } else if (value && name === 'email') {
      error.email = EMAIL_REGEX.test(value)
        ? ''
        : 'Please enter a valid email address.';
    }
    this.setState({ error });
  };

  handleInputFocus = event => {
    const { name } = event.target;

    // Remove error message when user starts modifying field
    this.setState({
      error: {
        ...this.state.error,
        [name]: ''
      }
    });
  };

  prepopulateValiationErrors = validations => {
    let error = null;
    validations.forEach(validation => {
      // Remove prefix
      const path = validation.path.split('contact.')[1];
      if (path) {
        if (path.indexOf('phone') > -1) {
          error = {
            ...error,
            [path.split('phone.')[1]]: validation.message
          };
        } else {
          error = {
            ...error,
            [path]: validation.message
          };
        }
      }
    });
    this.setState(prevState => {
      return {
        error: { ...prevState.error, ...error }
      };
    });
  };

  componentDidMount() {
    if (this.props.validations && this.props.validations.length > 0) {
      this.prepopulateValiationErrors(this.props.validations);
    }
  }

  render() {
    const contact =
      this.props.contact != null
        ? this.props.contact
        : {
            title: '',
            firstName: '',
            familyName: '',
            email: '',
            phone: {
              phoneNumberType: '',
              number: '',
              areaCode: ''
            }
          };
    const { title, firstName, familyName, email } = contact;
    const phone =
      contact.phone != null
        ? contact.phone
        : {
            phoneNumberType: '',
            number: '',
            areaCode: ''
          };
    const { phoneNumberType, number, areaCode } = phone;

    const { error } = this.state;

    const eventHandlers = {
      onChange: this.handleInputChange,
      onFocus: this.handleInputFocus,
      onBlur: this.handleInputBlur
    };

    return (
      <fieldset className="FormSection">
        <div className="row">
          <div className="col-sm-4">
            <legend>Contact details</legend>
          </div>
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-5">
                <div className="FormGroup">
                  <label htmlFor="title">Title</label>
                  <Select
                    name="title"
                    value={title || ''}
                    placeholder="Select a title..."
                    options={convertArrayToSelectOptions(TITLES)}
                    clearable={false}
                    onChange={this.handleTitleSelectChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FormGroup
                  name="firstName"
                  id="firstName"
                  label="First name"
                  required={true}
                  maxLength={NAME_MAX_LENGTH}
                  hint="40 characters or less"
                  value={firstName || ''}
                  error={error.firstName}
                  {...eventHandlers}
                />
              </div>
              <div className="col-sm-6">
                <FormGroup
                  name="familyName"
                  id="familyName"
                  label="Family Name"
                  required={true}
                  maxLength={NAME_MAX_LENGTH}
                  hint="40 characters or less"
                  value={familyName || ''}
                  error={error.familyName}
                  {...eventHandlers}
                />
              </div>
            </div>
            <FormGroup
              name="email"
              id="email"
              label="Email"
              type="email"
              placeholder="example@example.com"
              required={true}
              value={email || ''}
              error={error.email}
              {...eventHandlers}
            />
            <div className="row">
              <div className="col-sm-3">
                <FormGroup
                  name="areaCode"
                  id="areaCode"
                  label="Area code"
                  type="tel"
                  maxLength={AREA_CODE_MAX_LENGTH}
                  value={areaCode || ''}
                  error={error.areaCode}
                  {...eventHandlers}
                />
              </div>
              <div className="col-sm-9">
                <FormGroup
                  name="number"
                  id="number"
                  label={`${
                    phoneNumberType === 'MOBILE' ? 'Mobile' : 'Phone'
                  } number`}
                  type="string"
                  required={true}
                  value={(number && number.replace(/\s/g, '')) || ''}
                  error={error.number}
                  {...eventHandlers}
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default ContactDetailsSection;
