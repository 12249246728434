export const CLEARINGHOUSE_API = '/clearing-house/';
export const SEARCH_API = '/search/';
export const CHANNEL_API = '/channel/';

export const ROUTES = [
  '/your-details',
  '/payment-method',
  '/default-fund',
  '/t&c',
  '/finish'
];

export const STEPS = ['Your details', 'Payment method', 'Default fund', 'T&C'];

export const BUREAU_ROUTES = [
  '/your-details',
  '/payment-method',
  '/your-employer-clients',
  '/t&c',
  '/complete'
];

export const BUREAU_STEPS = [
  'Your details',
  'Payment method',
  'Client summary',
  'T&C'
];

export const ART_FUND_DETAILS = {
  australianBusinessNumber: '98503137921',
  productName: 'Australian Retirement Trust Super Savings',
  uniqueSuperannuationIdentifier: '60905115063003'
};

export const FUND_SEARCH_TYPES = [
  {
    value: 'name',
    label: 'Fund name'
  },
  {
    value: 'usi',
    label: 'USI'
  },
  {
    value: 'abn',
    label: 'ABN'
  }
];

export const BUSINESS_IDENTIFIER_TYPES = ['ABN', 'WPN'];
export const ADDRESS_TYPES = [
  {
    value: 'RESIDENTIAL',
    label: 'Business'
  },
  {
    value: 'POSTAL',
    label: 'Postal'
  }
];

export const BRISBANE_TIMEZONE = 'Australia/Brisbane';
