import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withAcceptance from './withAcceptance';
import TAndCAccordion from './TAndCAccordion';
import TAndCAccordionForBulkAcceptance from './TAndCAccordionForBulkAcceptance';
import Button from '../../components/Button/Button';
import Tick from '../../components/Tick/Tick';
import Cross from '../../components/Cross/Cross';
import './TAndC.css';
import Callout from '../../components/Callout/Callout';
import Loader from './../../components/Loader/Loader';
import ClearningHouseApi from '../../store/api/clearningHouse';
import { useDispatch, useSelector } from 'react-redux';
import { handleReturnToPayroll } from '../../store/thunks/global';
import { setNextEnabled } from '../../store/actions/globalActions';

const BeamTAndCWithAcceptance = withAcceptance(
  TAndCAccordion,
  'beam',
  'beam?type=MPSP',
  'Beam terms and conditions',
  'I have read and accept the terms and conditions and product information disclosed in the Beam Product Disclosure Statement and I have read Precision’s Privacy Policy and acknowledge that it explains how personal information is collected, used and disclosed by Precision in connection with Beam.'
);

const BulkTAndC = props => {
  const [newDirectDebitAuthorities, setDirectDebitAuthorities] = useState([]);
  const [beam, setBeam] = useState(false);
  const [directDebitAuthority, setDirectDebitAuthority] = useState(false);
  const [sunsuper, setSunsuper] = useState(false);
  const [canGoNext, setGoNext] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState([]);
  const [error, setError] = useState(null);
  const [
    directDebitAuthorityIdsToAccept,
    setDirectDebitAuthorityIdsToAccept
  ] = useState([]);
  const dispatch = useDispatch();
  const {
    participant,
    clientParticipantNumber,
    userAcceptingTermsAndConditions,
    payrollName,
    redirectUrl,
    loading
  } = useSelector(state => state.participant.data);
  // const { employerRegisterSunsuper } = useSelector(state => state.global.data);
  const { directDebitAuthorities } = participant;

  const handleDDASelectAll = () => {
    setDirectDebitAuthorityIdsToAccept(
      newDirectDebitAuthorities.map(
        authority => authority.directDebitAuthorityId
      )
    );
  };

  const handleDDASelect = id => event => {
    if (event.target.checked) {
      setDirectDebitAuthorityIdsToAccept([
        ...directDebitAuthorityIdsToAccept,
        id
      ]);
    } else {
      setDirectDebitAuthorityIdsToAccept(
        directDebitAuthorityIdsToAccept.filter(dda => dda !== id)
      );
    }
  };

  const handleAcceptance = (name, value) => {
    switch (name) {
      case 'beam':
        setBeam(value);
        break;
      case 'directDebitAuthority':
        setDirectDebitAuthority(value);
        break;
      case 'sunsuper':
        setSunsuper(value);
        break;
      default:
    }
  };

  const updateSuccessStatus = res => {
    setStatus({ ...status, res });
  };

  const handleClick = () => {
    setSubmitting(true);
    setStatus([]);
    setError(null);
    acceptBeam(clientParticipantNumber)
      .then(res => {
        return acceptDDAs();
      })
      .then(res => {
        updateSuccessStatus(
          'All terms and conditions are accepted, redirecting...'
        );

        setTimeout(() => {
          setSubmitting(false);

          // Navigate to next step
          props.history.push({
            pathname: '/finish',
            hash: props.location.hash
          });
        }, 3000);
      })
      .catch(error => {
        setSubmitting(false);
        setError(error);
      });
  };

  const acceptBeam = async () => {
    try {
      const res = await ClearningHouseApi.acceptBeamTermsMPSP(
        userAcceptingTermsAndConditions,
        clientParticipantNumber
      );
      return await Promise.resolve('Beam');
    } catch (error) {
      if (error.response && error.response.data) {
        return Promise.reject(
          `Beam acceptance request failed: ${error.response.data.errorCode} - ${
            error.response.data.message
          }`
        );
      } else {
        return Promise.reject('Beam acceptance request failed');
      }
    }
  };

  const acceptDDAs = async () => {
    if (newDirectDebitAuthorities.length > 0) {
      try {
        const res = await ClearningHouseApi.acceptDDAsMPSP(
          userAcceptingTermsAndConditions,
          clientParticipantNumber,
          directDebitAuthorityIdsToAccept
        );
        return await Promise.resolve('Direct Debit Request');
      } catch (error) {
        if (error.response && error.response.data) {
          return Promise.reject(
            `Direct Debit acceptance request failed: ${
              error.response.data.errorCode
            } - ${error.response.data.message}`
          );
        } else {
          return Promise.reject('Direct Debit acceptance request failed');
        }
      }
    } else {
      return Promise.resolve('Direct Debit Request passed');
    }
  };

  const initData = () => {
    dispatch(setNextEnabled(false));
    setBeam(participant.termsAndConditionsAcceptanceDate !== null);
    setDirectDebitAuthorities(
      directDebitAuthorities.filter(authority => {
        return (
          authority.endDate === null &&
          authority.termsAndConditionsAcceptanceDate === null
        );
      })
    );
  };

  useEffect(() => {
    document.title = `Beam - Terms & Conditions`;
    initData();
  }, []);

  useEffect(
    () => {
      const goNext =
        beam &&
        (newDirectDebitAuthorities.length === 0 ||
          (newDirectDebitAuthorities.length > 0 &&
            newDirectDebitAuthorities.length ===
              directDebitAuthorityIdsToAccept.length));
      setGoNext(goNext);
    },
    [newDirectDebitAuthorities, directDebitAuthorityIdsToAccept, beam]
  );

  const newTermsToAccept = parseInt(1 + newDirectDebitAuthorities.length, 10);

  return (
    <div>
      <section className="Section Section--leading">
        <h1>Terms and conditions</h1>
      </section>
      {loading && <Loader text="Loading..." />}
      <Callout position="bottom" style={{ width: '50%' }}>
        <h3>You have {newTermsToAccept} terms and condition(s) to accept.</h3>
      </Callout>
      <section className="Section">
        <BeamTAndCWithAcceptance onAcceptance={handleAcceptance} />
      </section>
      {newDirectDebitAuthorities &&
        newDirectDebitAuthorities.length > 0 && (
          <section className="Section">
            <TAndCAccordionForBulkAcceptance
              name="direct-debit-authority"
              url="direct-debit-authority?type=MPSP"
              title="Direct Debit terms and conditions"
            >
              {() => (
                <table className="Table--terms-acceptance">
                  <thead>
                    <tr>
                      <th>ABN/WPN</th>
                      <th>Account details</th>
                      <th>Applies to employer client</th>
                      <th>
                        I have read and accept the Direct Debit terms and
                        conditions outlined above
                        <br />(
                        <Button
                          type="button"
                          customclass="Button--select-all"
                          onClick={handleDDASelectAll}
                        >
                          Select all
                        </Button>
                        )
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newDirectDebitAuthorities.map(authority => {
                      return (
                        <tr key={authority.directDebitAuthorityId}>
                          <td>
                            {
                              authority.businessIdentifier
                                .businessIdentifierType
                            }
                            :{' '}
                            <strong>
                              {authority.businessIdentifier.value}
                            </strong>
                          </td>
                          <td>
                            <div>
                              BSB: <strong>{authority.account.bsb}</strong>
                            </div>
                            <div>
                              Account number:{' '}
                              <strong>{authority.account.accountNumber}</strong>
                            </div>
                            <div>
                              Account name:{' '}
                              <strong>{authority.account.accountName}</strong>
                            </div>
                          </td>
                          <td>
                            {authority.employers &&
                            authority.employers.length > 0 ? (
                              <ul>
                                {authority.employers
                                  .map(
                                    employerAccount =>
                                      participant.employers.find(
                                        a =>
                                          a.clientEmployerNumber ===
                                          employerAccount.clientEmployerNumber
                                      ).organisationName
                                  )
                                  .map(item => (
                                    <li key={item}>{item}</li>
                                  ))}
                              </ul>
                            ) : (
                              <em>* Not specified</em>
                            )}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id={`dda-${authority.directDebitAuthorityId}`}
                              checked={directDebitAuthorityIdsToAccept.includes(
                                authority.directDebitAuthorityId
                              )}
                              onChange={handleDDASelect(
                                authority.directDebitAuthorityId
                              )}
                            />
                            <label
                              htmlFor={`dda-${
                                authority.directDebitAuthorityId
                              }`}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </TAndCAccordionForBulkAcceptance>
          </section>
        )}
      <section className="Section PageNavGroup">
        <Button
          type="button"
          primary
          hollow
          customclass="Button--cancel"
          onClick={() => {
            dispatch(handleReturnToPayroll(payrollName, redirectUrl));
          }}
        >
          <span>Cancel</span>
        </Button>
        <Link
          className="Button Button--primary Button--hollow Button--prev"
          to={{
            pathname: '/your-employer-clients',
            hash: props.location.hash
          }}
        >
          <span>Back</span>
        </Link>
        <Button
          primary
          customclass="Button--next"
          disabled={!canGoNext || submitting || loading}
          onClick={handleClick}
        >
          <span>{submitting ? 'Submitting...' : 'Next'}</span>
        </Button>
      </section>
      <div className="StatusPanel">
        <ul>
          {status.length > 0 &&
            status.map(update => (
              <li key={update}>
                <Tick />
                {update}
              </li>
            ))}
          {error != null && (
            <li key={error}>
              <Cross />
              {error}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(props => <BulkTAndC {...props} />);
