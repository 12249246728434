import isAfter from 'date-fns/is_after';
import { formatToTimeZone } from 'date-fns-timezone';
import { BRISBANE_TIMEZONE } from './config';

function getDateInYYYYMMDDFormat() {
  return formatToTimeZone(new Date(), 'YYYY-MM-DD', {
    timeZone: BRISBANE_TIMEZONE
  });
}

// See if an item is active by checking its endDate being either unset (null) or set to a date in the future
function isActive(endDate) {
  const currentDate = new Date();
  return endDate === null || isAfter(endDate, currentDate);
}

export { getDateInYYYYMMDDFormat, isActive };
