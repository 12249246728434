import { combineReducers } from 'redux';
import { participantReducer } from './reducers/participantReducer';
import { paymentReducer } from './reducers/paymentReducer';
import { globalReducer } from './reducers/globalReducer';

export const rootReducer = combineReducers({
  participant: participantReducer,
  payment: paymentReducer,
  global: globalReducer
});
