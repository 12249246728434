import React from 'react';
import Button from '../../components/Button/Button';
import Alert from '../../components/Alert/Alert';
import { isActive } from '../../utils/date';
import { useSelector } from 'react-redux';

const ClientEntry = props => {
  const { entry, onEdit, fundNameReference } = props;

  const { participant, validations } = useSelector(
    state => state.participant.data
  );

  const defaultAuthority = participant.directDebitAuthorities
    .map((authority, index) => ({
      ...authority,
      index
    }))
    .filter(
      authority =>
        authority.employers &&
        authority.employers.length === 0 &&
        authority.endDate === null &&
        isActive(authority.endDate)
    );

  const associatedAuthority = participant.directDebitAuthorities
    .map((authority, index) => ({
      ...authority,
      index
    }))
    .filter(
      authority =>
        authority.employers &&
        authority.employers.find(
          employer =>
            employer.clientEmployerNumber === entry.clientEmployerNumber
        ) &&
        isActive(authority.endDate)
    );

  const directDebitAuthorityErrors = associatedAuthority
    ? validations.filter(validation =>
        validation.path.includes(
          `directDebitAuthorities[${associatedAuthority.index}]`
        )
      )
    : [];

  return (
    <tr>
      <td>
        {entry.validations.length > 0 ? (
          <Alert size="24">
            {entry.validations.length} error
            {entry.validations.length > 1 && 's'}
          </Alert>
        ) : entry.defaultFunds == null || entry.defaultFunds.length === 0 ? (
          <Alert size="24">Missing default fund</Alert>
        ) : (
          'Ready'
        )}
      </td>
      <td>{entry.businessIdentifier ? entry.businessIdentifier.value : '-'}</td>
      <td>{entry.organisationName ? entry.organisationName : '-'}</td>
      <td>
        {directDebitAuthorityErrors.length > 0 ? (
          <Alert size="24" />
        ) : associatedAuthority !== 'undefined' &&
          associatedAuthority.length !== 0 ? (
          associatedAuthority
            .filter(authority => isActive(authority.endDate))
            .map(authority => (
              <dl key={authority.directDebitAuthorityId}>
                <dt>BSB:</dt> <dd>{authority.account.bsb}</dd>
                <br />
                <dt>Account number:</dt>{' '}
                <dd>{authority.account.accountNumber}</dd>
                <br />
                <dt>Account name:</dt> <dd>{authority.account.accountName}</dd>
              </dl>
            ))
        ) : (
          defaultAuthority
            .filter(authority => isActive(authority.endDate))
            .map(authority => (
              <dl key={authority.directDebitAuthorityId}>
                <dt>BSB:</dt> <dd>{authority.account.bsb}</dd>
                <br />
                <dt>Account number:</dt>{' '}
                <dd>{authority.account.accountNumber}</dd>
                <br />
                <dt>Account name:</dt> <dd>{authority.account.accountName}</dd>
              </dl>
            ))
        )}
      </td>
      <td>
        {entry.defaultFunds && entry.defaultFunds.length > 0 ? (
          <ul>
            {entry.defaultFunds
              .filter(fund => isActive(fund.endDate))
              .map(fund => (
                <li key={fund.usi}>{fundNameReference[fund.usi]}</li>
              ))}
          </ul>
        ) : (
          <Alert size="24" />
        )}
      </td>
      <td>
        <Button type="button" onClick={onEdit(entry.clientEmployerNumber)}>
          Edit
        </Button>
      </td>
    </tr>
  );
};

export default ClientEntry;
