import React from 'react';
import Accordion from '../Accordion/Accordion';

function RegisteringWithART(props) {
  const { employerRegisterART, onChange } = props;

  return (
    <Accordion
      triggerId="trigger-2"
      panelId="panel-2"
      title="Consider joining Australian Retirement Trust as an employer"
      expanded={true}
    >
      <p>
        As one of Australia’s largest super funds, Australian Retirement Trust
        is proud to take care of over $200 billion in retirement savings for
        more than two million members. So, it’s possible that some of your
        employees have already chosen Australian Retirement Trust. By joining,
        you can easily track payments to the fund and create new member accounts
        for your employees. Beam is powered by Australian Retirement Trust but
        you don’t have to contribute to Australian Retirement Trust to use Beam.
      </p>
      <p>
        <input
          id="sunsuper-employer"
          type="checkbox"
          name="sunsuper-employer"
          checked={employerRegisterART}
          onChange={onChange}
        />
        <label htmlFor="sunsuper-employer">
          <strong>
            Please register me as an Australian Retirement Trust participating
            employer
          </strong>
        </label>
      </p>
    </Accordion>
  );
}

export default RegisteringWithART;
