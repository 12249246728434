import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';

class SearchApi {
  static async getBsbInfo(bsb) {
    try {
      const bsbInfo = await axios.get(`/search/bsb/${bsb}`);
      return bsbInfo.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getFundDetails(type, value) {
    try {
      const funds = await axios.get(`/search/funds/${type}/${value}`);
      return funds.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getFundsDetails(fundUSIs) {
    try {
      const funds = await axios.post('/search/funds/validate', fundUSIs);
      return funds.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default SearchApi;
