import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from './../../components/Button/Button';
import Tick from './../../components/Tick/Tick';
import './Accordion.css';

const Accordion = ({
  title,
  subtitle,
  hasTick,
  triggerId = 'trigger-1',
  panelId = 'panel-1',
  expanded = true,
  children
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  const accordionPanelClass = classNames({
    'Accordion-panel': true,
    animateIn: isExpanded
  });

  return (
    <dl className="Accordion" role="presentation">
      <dt role="heading">
        <Button
          customclass="Accordion-trigger"
          aria-expanded={isExpanded}
          id={triggerId}
          aria-controls={panelId}
          onClick={handleClick}
        >
          <h3 className="Accordion-title">{title}</h3>
          {hasTick && <Tick customclass="Accordion-tick" />}
          <span className="Accordion-icon" />

          {subtitle && (
            <span>
              <br />
              <span className="Accordion-subtitle">{subtitle}</span>
            </span>
          )}
        </Button>
      </dt>
      <dd
        className={accordionPanelClass}
        id={panelId}
        role="region"
        aria-labelledby={triggerId}
        aria-hidden={!isExpanded}
      >
        <div className="Accordion-inner">{children}</div>
      </dd>
    </dl>
  );
};

export default Accordion;
