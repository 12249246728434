import {
  GET_PAYMENT_OPTIONS,
  GET_PAYMENT_OPTIONS_FAILURE,
  GET_PAYMENT_OPTIONS_SUCCESS,
  SAVE_PAYMENT_OPTIONS
} from '../actions/paymentActions';

const initialState = {
  loading: false,
  data: { bureauProxyPayment: false },
  error: null
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_OPTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        error: null
      };
    case GET_PAYMENT_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        data: { bureauProxyPayment: false },
        error: action.payload
      };
    case SAVE_PAYMENT_OPTIONS:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
};

export { paymentReducer };
