import React from 'react';
import PropTypes from 'prop-types';

function ExternalLink({ url, text }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {text ? text : url}
    </a>
  );
}

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default ExternalLink;
