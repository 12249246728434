export const GET_PAYMENT_OPTIONS = 'GET_PAYMENT_OPTIONS';
export const GET_PAYMENT_OPTIONS_SUCCESS = 'GET_PAYMENT_OPTIONS_SUCCESS';
export const GET_PAYMENT_OPTIONS_FAILURE = 'GET_PAYMENT_OPTIONS_FAILURE';
export const SAVE_PAYMENT_OPTIONS = 'SAVE_PAYMENT_OPTIONS';

export const getPaymentOptions = () => {
  return {
    type: GET_PAYMENT_OPTIONS
  };
};

export const getPaymentOptionsSuccess = data => {
  return {
    type: GET_PAYMENT_OPTIONS_SUCCESS,
    payload: data
  };
};

export const getPaymentOptionsFailure = error => {
  return {
    type: GET_PAYMENT_OPTIONS_FAILURE,
    payload: error
  };
};

export const savePaymentOptions = data => {
  return {
    type: SAVE_PAYMENT_OPTIONS,
    payload: data
  };
};
