import React from 'react';
import className from 'classnames';
import './Cross.css';

function Cross(props) {
  const { customclass, ...rest } = props;
  const crossClasses = className({
    Cross: true,
    [customclass]: customclass
  });
  const size = props.size || 16;

  return (
    <span className={crossClasses}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 18 18"
        {...rest}
      >
        <path
          fill="#ef7026"
          fillRule="evenodd"
          d="M17.5765182,15.5149798 L15.5157895,17.5757085 C15.242915,17.8502024 14.8639676,18 14.4850202,18 C14.1060729,18 13.7279352,17.8502024 13.4550607,17.5757085 L9.00080972,13.1206478 L4.5465587,17.5757085 C4.27368421,17.8502024 3.89473684,18 3.51578947,18 C3.13684211,18 2.75789474,17.8502024 2.48502024,17.5757085 L0.424291498,15.5149798 C0.151417004,15.2437247 0,14.8631579 0,14.48583 C0,14.1060729 0.151417004,13.7279352 0.424291498,13.454251 L4.87854251,9.00080972 L0.424291498,4.54574899 C0.151417004,4.27368421 0,3.89392713 0,3.51659919 C0,3.13684211 0.151417004,2.75870445 0.424291498,2.48502024 L2.48502024,0.423481781 C2.75789474,0.152226721 3.13684211,0 3.51578947,0 C3.89473684,0 4.27368421,0.152226721 4.5465587,0.423481781 L9.00080972,4.87935223 L13.4550607,0.423481781 C13.7279352,0.152226721 14.1060729,0 14.4850202,0 C14.8639676,0 15.242915,0.152226721 15.5157895,0.423481781 L17.5765182,2.48502024 C17.8493927,2.75870445 18,3.13684211 18,3.51659919 C18,3.89392713 17.8493927,4.27368421 17.5765182,4.54574899 L13.1222672,9.00080972 L17.5765182,13.454251 C17.8493927,13.7279352 18,14.1060729 18,14.48583 C18,14.8631579 17.8493927,15.2437247 17.5765182,15.5149798"
        />
      </svg>
    </span>
  );
}

export default Cross;
