import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import { updateToken } from './utils/token';
import NotFound from './pages/NotFound/NotFound';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import YourDetails from './pages/YourDetails/YourDetails';
import PaymentMethod from './pages/PaymentMethod/PaymentMethod';
import DefaultFund from './pages/DefaultFund/DefaultFund';
import YourEmployers from './pages/YourEmployers/YourEmployers';
import BulkTAndC from './pages/TAndC/BulkTAndC';
import TAndC from './pages/TAndC/TAndC';
import Finish from './pages/Finish/Finish';
import { getParameterByName } from './utils/string';
import { useDispatch, useSelector } from 'react-redux';
import { initParticipant as fetchParticipant } from './store/thunks/participant';
import { fetchPaymentOptions } from './store/thunks/payment';

const BureauRoutes = props => {
  return (
    <React.Fragment>
      <Route
        path="/your-employer-clients"
        render={() => <YourEmployers {...props} />}
      />
      <Route path="/t&c" render={() => <BulkTAndC {...props} />} />
      <Route path="/finish" render={() => <Finish {...props} />} />
    </React.Fragment>
  );
};

const NonBureauRoutes = props => {
  return (
    <React.Fragment>
      <Route path="/default-fund" render={() => <DefaultFund {...props} />} />
      <Route path="/t&c" render={() => <TAndC {...props} />} />
      <Route path="/finish" render={() => <Finish {...props} />} />
    </React.Fragment>
  );
};

const AuthRoute = props => {
  const dispatch = useDispatch();
  const { location } = props;
  const [isLoading, setLoading] = useState(true);
  const { loading, isBureau } = useSelector(state => state.participant.data);

  const authenticate = () => {
    let token = getParameterByName(location.hash, 'access_token');
    // Reuse saved token from localStorage if no token can be found from the parameter fragment
    token = token != null ? token : window.localStorage.getItem('access_token');

    setLoading(token != null);
    if (token != null) {
      updateToken(token);
      if (checkIfAuthenticated()) {
        dispatch(fetchPaymentOptions());
        dispatch(fetchParticipant());
      }
      setLoading(false);
    }
  };

  const checkIfAuthenticated = () => {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  };

  useEffect(() => authenticate(), []);

  if (isLoading || loading) {
    return <Loader text="Retrieving your information" />;
  }

  if (!checkIfAuthenticated()) {
    return (
      <Redirect
        to={{
          pathname: '/forbidden',
          state: { from: location }
        }}
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={props => (
          <Redirect {...props} to={`/disclaimer${location.hash}`} />
        )}
      />
      <Route path="/disclaimer" render={() => <Disclaimer {...props} />} />
      <Route path="/your-details" render={() => <YourDetails {...props} />} />
      <Route
        path="/payment-method"
        render={() => <PaymentMethod {...props} />}
      />
      {isBureau ? <BureauRoutes {...props} /> : <NonBureauRoutes {...props} />}
      <Route component={NotFound} />
    </Switch>
  );
};

export default props => <AuthRoute {...props} />;
