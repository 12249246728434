import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import img from './beam-mascot.svg';
import { useSelector } from 'react-redux';
import ClearningHouseApi from '../../store/api/clearningHouse';

const Disclaimer = ({ location }) => {
  const [disclaimer, setDisclaimer] = useState(null);
  const { isBureau } = useSelector(state => state.participant.data);

  const createMarkup = markup => {
    return { __html: markup };
  };

  useEffect(() => {
    ClearningHouseApi.getDisclaimer().then(disclaimer =>
      setDisclaimer(disclaimer)
    );
  }, []);

  return (
    <div>
      <section className="Section Section--leading">
        <h1>Lighten your workload. Brighten your day.</h1>
        <div className="row">
          <div className="col-sm-7">
            <p>
              You're only four simple steps from a brighter way to pay super
              from your payroll.
            </p>
            <Link
              className="Button Button--primary Button--next"
              to={{
                pathname: '/your-details',
                hash: location.hash
              }}
              style={{ display: 'inline-block', marginTop: '4rem' }}
            >
              <span>Start</span>
            </Link>
          </div>
          <div className="col-sm-5">
            <img src={img} alt="" />
          </div>
        </div>
      </section>
      <section className="Section">
        <h3>Important information</h3>
        <p>
          Beam is part of Australian Retirement Trust Group. Australian
          Retirement Trust is one of Australia’s biggest and fastest growing
          super funds.
        </p>

        <p>
          Beam is issued by Precision Administration Services Pty Ltd
          (Precision), (ABN 47 098 977 667, AFSL No. 246604). Precision is
          wholly owned by Australian Retirement Trust Pty Ltd (ABN 88 010 720
          840, AFSL No. 228975) as Trustee for the Australian Retirement Trust
          (ABN 60 905 115 063).
        </p>
        {disclaimer && (
          <div
            style={{ marginTop: '2rem' }}
            dangerouslySetInnerHTML={createMarkup(disclaimer)}
          />
        )}
      </section>
    </div>
  );
};

export default Disclaimer;
