export const handleReturnToPayroll = (payrollName, redirectUrl) => {
  return async dispatch => {
    if (
      window.confirm(
        `Do you want to leave this site and go back to ${payrollName}? Changes that you made on this page may not be saved.`
      )
    ) {
      window.location.replace(appendIncompleteFlagToRedirectUrl(redirectUrl));
    }
  };
};

const appendIncompleteFlagToRedirectUrl = originalUrl => {
  const url = new URL(originalUrl);
  const { origin, pathname, search, hash } = url;
  let params = new URLSearchParams(search);
  params.append('completed', false);
  return `${origin}${pathname}?${params}${hash}`;
};
