import {
  getParticipant,
  getParticipantSuccess,
  getParticipantFailure,
  updateParticipant,
  updateParticipantSuccess,
  updateParticipantFailure,
  saveParticipantData
} from '../actions/participantActions';
import ClearningHouseApi from '../api/clearningHouse';
import { updateToken } from '../../utils/token';
import { getDateInYYYYMMDDFormat } from '../../utils/date';
import { removeAllWhitespaces } from '../../utils/string';
import { handleError } from '../../utils/error';
import { setNextEnabled } from '../actions/globalActions';
import { isActive } from '../../utils/date';
import { savePaymentOptions } from '../actions/paymentActions';

export const initParticipant = () => {
  return async dispatch => {
    dispatch(getParticipant());
    try {
      const response = await ClearningHouseApi.getParticipant();

      const {
        participant,
        validationResponse,
        payrollName,
        redirectUrl
      } = response.data;

      const {
        clientParticipantNumber,
        employers,
        directDebitAuthorities,
        userAcceptingTermsAndConditions
      } = participant;

      // Label user as a bureau when the participant and employer id (abn or wpn) don't match
      const isBureau =
        employers &&
        employers[0] != null &&
        employers[0].businessIdentifier != null &&
        employers[0].businessIdentifier.value !==
          participant.businessIdentifier.value;

      // Find the list of paths to the default fund that has a USI validation error
      const pathsWithUsiErrors = validationResponse.validations
        .map(validation => validation.path)
        .filter(path => {
          return path.includes('.usi');
        })
        .map(path => path.split('.usi')[0]);

      /**
       * Conduct the following conversions on the participant data to avoid confusing error messages on page load:
       *
       * - Remove all whitespaces from all business identifier values in the participant payload, e.g. convert "51 824 753 556" to "51824753556";
       * - Populate the direct debit requests and employers' default funds with startDate being current time;
       * - Cascade the businessIdentifier from participant if no employer specified for a direct debit request;
       * - Drop any default fund that has an error of unfound USI
       */
      const formattedParticipantData = {
        ...participant,
        businessIdentifier: {
          businessIdentifierType:
            participant.businessIdentifier.businessIdentifierType,
          value: removeAllWhitespaces(participant.businessIdentifier.value)
        },
        directDebitAuthorities: directDebitAuthorities.map(authority => ({
          ...authority,
          businessIdentifier:
            authority.employers &&
            authority.employers.length > 0 &&
            authority.businessIdentifier != null
              ? {
                  businessIdentifierType: authority.businessIdentifier
                    ? authority.businessIdentifier.businessIdentifierType
                    : null,
                  value: authority.businessIdentifier
                    ? removeAllWhitespaces(authority.businessIdentifier.value)
                    : null
                }
              : {
                  businessIdentifierType:
                    participant.businessIdentifier.businessIdentifierType,
                  value: removeAllWhitespaces(
                    participant.businessIdentifier.value
                  )
                },
          startDate: authority.startDate
            ? authority.startDate
            : getDateInYYYYMMDDFormat()
        })),
        employers: employers.map((employer, employerIndex) => ({
          ...employer,
          businessIdentifier: {
            businessIdentifierType: employer.businessIdentifier
              ? employer.businessIdentifier.businessIdentifierType
              : null,
            value: employer.businessIdentifier
              ? removeAllWhitespaces(employer.businessIdentifier.value)
              : null
          },
          defaultFunds: employer.defaultFunds
            .filter((fund, fundIndex) => {
              return !pathsWithUsiErrors.includes(
                `employers[${employerIndex}].defaultFunds[${fundIndex}]`
              );
            })
            .map(fund => ({
              ...fund,
              startDate: fund.startDate
                ? fund.startDate
                : getDateInYYYYMMDDFormat()
            }))
        }))
      };

      dispatch(
        getParticipantSuccess({
          participant: formattedParticipantData,
          clientParticipantNumber,
          isBureau,
          payrollName,
          redirectUrl,
          userAcceptingTermsAndConditions,
          validations: validationResponse.validations,
          loading: false
        })
      );

      if (
        participant.directDebitAuthorities &&
        participant.directDebitAuthorities.find(
          authority =>
            isActive(authority.endDate) &&
            ((authority.termsAndConditionsAcceptanceDate !== null &&
              authority.employers.length === 0) ||
              (authority.businessIdentifier != null &&
                authority.businessIdentifier.value ===
                  participant.businessIdentifier.value))
        )
      ) {
        dispatch(savePaymentOptions({ bureauProxyPayment: true }));
      }
    } catch (error) {
      dispatch(saveParticipantData({ loading: false }));
      dispatch(getParticipantFailure(error));
      handleError(error);
    }
  };
};

export const updateParticipantDetails = async participant => {
  return async dispatch => {
    dispatch(updateParticipant());
    try {
      const validatedResponse = await ClearningHouseApi.updateParticipant(
        participant
      );
      const {
        participantDetail,
        validations,
        token,
        processed
      } = validatedResponse;
      updateToken(token);
      dispatch(
        updateParticipantSuccess({
          participant: processed ? participantDetail : participant,
          validations: validations
        })
      );
    } catch (error) {
      dispatch(saveParticipantData({ loading: false }));
      dispatch(updateParticipantFailure(error));
      handleError(error);
    }
  };
};

export const saveParticipantDetails = participant => {
  return async dispatch => {
    dispatch(updateParticipant());
    try {
      const response = await ClearningHouseApi.updateParticipant(participant);
      const { participantDetail, validations, processed, token } = response;
      updateToken(token);
      dispatch(
        updateParticipantSuccess({
          participant: processed ? participantDetail : participant,
          validations,
          loading: false
        })
      );
      dispatch(setNextEnabled(validations == null || validations.length === 0));
    } catch (error) {
      dispatch(saveParticipantData({ loading: false }));
      dispatch(updateParticipantFailure(error));
      handleError(error);
    }
  };
};
