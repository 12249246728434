/**
 * validate WPN
 * @param {string} wpn
 * @return {boolean} is WPN valid
 *    
 * 0. WPN must be 11 digits long
 * 1. WPN must start with double zeroes        
 * 2. Multiply each of the digits in this new number by a "weighting factor" based on its position    
 * 3. Sum the resulting 11 products         
 * 4. Divide the sum total by 11, noting the remainder      
 * 5. If the remainder is zero the number is valid  
 */
function validateWPN(wpn) {
  let isValid = true;

  // Remove all spaces
  wpn = wpn.replace(/\s/g, '');

  // Rule 0, 1
  if (wpn.length !== 11 || !wpn.substring(0, 2) === '00') {
    isValid = false;
  }

  if (isValid) {
    let weightedSum = 0;
    const weight = [0, 0, 1, 4, 3, 7, 5, 8, 6, 9, 10];

    // Rules: 2, 3
    for (let i = 0; i < weight.length; i++) {
      weightedSum += wpn[i] * weight[i];
    }

    // Rules: 4, 5
    isValid = weightedSum % 11 === 0;
  }

  return isValid;
}

export default validateWPN;
