import { handleError } from '../../utils/error';
import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';

class ClearningHouseApi {
  static async getText(url) {
    try {
      let terms = await axios.get(`/clearing-house/terms/${url}`, {
        headers: {
          Accept: 'text/plain'
        }
      });
      return terms.data;
    } catch (error) {
      handleError(error);
    }
  }

  static async getDisclaimer() {
    try {
      let disclaimer = await axios.get('/clearing-house/terms/disclaimer');
      return disclaimer.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async acceptSuperTerms(
    userAcceptingTermsAndConditions,
    participantId,
    employerId
  ) {
    try {
      let searchParams = new URLSearchParams();
      searchParams.append('username', userAcceptingTermsAndConditions);
      return await axios.post(
        `/clearing-house/participant/${participantId}/employer/${employerId}/terms-acceptance`,
        searchParams,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async acceptBeamTerms(userAcceptingTermsAndConditions, participantId) {
    try {
      let searchParams = new URLSearchParams();
      searchParams.append('username', userAcceptingTermsAndConditions);
      return await axios.post(
        `/clearing-house/participant/${participantId}/terms-acceptance`,
        searchParams,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async acceptBeamTermsMPSP(
    userAcceptingTermsAndConditions,
    participantId
  ) {
    try {
      return await axios.post(
        `/clearing-house/participant/${participantId}/terms-acceptance?type=MPSP&username=${userAcceptingTermsAndConditions}`
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deactivateDDA(participantId, directDebitAuthorityId) {
    try {
      return await axios.put(
        `/clearing-house//participant/${participantId}/directDebitAuthority/${directDebitAuthorityId}/deactivate`
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async acceptDDAsMPSP(
    userAcceptingTermsAndConditions,
    participantId,
    directDebitAuthorityIds
  ) {
    try {
      return await axios.post(
        `/clearing-house/participant/${participantId}/directDebitAuthority/terms-acceptance?type=MPSP&username=${userAcceptingTermsAndConditions}`,
        directDebitAuthorityIds
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async acceptDDAs(
    userAcceptingTermsAndConditions,
    participantId,
    directDebitAuthorityIds
  ) {
    try {
      return await axios.post(
        `/clearing-house/participant/${participantId}/directDebitAuthority/terms-acceptance?username=${encodeURI(
          userAcceptingTermsAndConditions
        )}`,
        directDebitAuthorityIds
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getParticipant() {
    try {
      let response = await axios.get(
        '/clearing-house/participant-details/validated'
      );
      return response;
    } catch (error) {
      //TODO: move this to the thunk
      handleError(error);
    }
  }

  static async updateParticipant(participant) {
    try {
      let response = await axios.post(
        '/clearing-house/participant-details/validated',
        participant
      );
      return response.data;
    } catch (error) {
      //TODO: move this to the thunk
      handleError(error);
    }
  }
}

export default ClearningHouseApi;
