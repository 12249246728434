import React from 'react';
import { useSelector } from 'react-redux';

const Forbidden = props => {
  const { payrollName, redirectUrl } = useSelector(
    state => state.participant.data
  );
  return (
    <div>
      <section className="Section Section--leading">
        <h1>403 Access denied.</h1>
        <h2>
          Your access token is missing or has expired.
          <br />
          Please restart the process from your payroll system.
        </h2>
      </section>

      <React.Fragment>
        {redirectUrl && redirectUrl.length > 0 && (
          <a className="Button Button--primary" href={redirectUrl}>
            Back to {payrollName}
          </a>
        )}
      </React.Fragment>
    </div>
  );
};

export default Forbidden;
