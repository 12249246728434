import React from 'react';
import PropTypes from 'prop-types';
import './Callout.css';

class Callout extends React.PureComponent {
  render() {
    const { position, children } = this.props;
    return (
      <div className={`Callout Callout--${position}`} {...this.props}>
        {children}
      </div>
    );
  }
}

Callout.propTypes = {
  position: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default Callout;
