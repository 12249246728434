import React from 'react';

function SearchFundResults(props) {
  const { funds, selected, onFundToggle } = props;

  return (
    <div className="FundSearchResult">
      <h3>
        Display {funds.length} most relevant result{funds.length > 1 && 's'}{' '}
        found:
      </h3>
      <ul className="FundList">
        {funds.map(fund => {
          const checked =
            selected
              .map(selectedFund => selectedFund.uniqueSuperannuationIdentifier)
              .indexOf(fund.uniqueSuperannuationIdentifier) > -1;

          return (
            <li key={fund.uniqueSuperannuationIdentifier}>
              <input
                type="checkbox"
                name={fund.uniqueSuperannuationIdentifier}
                id={fund.uniqueSuperannuationIdentifier}
                checked={checked}
                onChange={onFundToggle}
              />
              <label htmlFor={fund.uniqueSuperannuationIdentifier}>
                {fund.productName} (USI: {fund.uniqueSuperannuationIdentifier})
              </label>
            </li>
          );
        })}
      </ul>
      <p>
        If you cannot see what you're looking for, try searching for a more
        specific name, or the fund's USI or ABN.
      </p>
    </div>
  );
}

export default SearchFundResults;
