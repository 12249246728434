import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from './../../components/Button/Button';
import PaymentOptions from './PaymentOptions';
import PaymentSelection from './PaymentSelection';
import { useDispatch, useSelector } from 'react-redux';
import './PaymentMethod.css';
import { isActive } from './../../utils/date';
import { setNextEnabled } from '../../store/actions/globalActions';
import { savePaymentOptions } from '../../store/actions/paymentActions';
import { handleReturnToPayroll } from '../../store/thunks/global';
import ClearningHouseApi from '../../store/api/clearningHouse';
import { getDateInYYYYMMDDFormat } from './../../utils/date';
import { saveParticipantDetails } from '../../store/thunks/participant';

const PaymentMethod = props => {
  const dispatch = useDispatch();
  const [useDirectDebit, setUseDirectDebit] = useState(true);
  const { history, location } = props;
  const {
    isBureau,
    participant,
    loading,
    payrollName,
    redirectUrl,
    clientParticipantNumber
  } = useSelector(state => state.participant.data);
  const {
    bureauProxyPayment,
    acceptDirectDebit,
    onlyAcceptDirectDebit
  } = useSelector(state => state.payment.data);
  const [isInitialized, setInitialized] = useState(false);

  const navigateToNext = () => {
    // Navigate to next step
    history.push({
      pathname: isBureau ? '/your-employer-clients' : '/default-fund',
      hash: location.hash
    });
  };

  const saveDirectDebitAuthorities = directDebitAuthorities => {
    dispatch(
      saveParticipantDetails({
        ...participant,
        directDebitAuthorities: directDebitAuthorities.map(authority => ({
          ...authority,
          startDate: authority.startDate
            ? authority.startDate
            : getDateInYYYYMMDDFormat()
        }))
      })
    );
  };

  const updatedBureauProxyPayment = bureauProxyPaymentValue => {
    dispatch(
      savePaymentOptions({ bureauProxyPayment: bureauProxyPaymentValue })
    );
    // No proxy payment for bureau: deactivating the accounts from the participant level
    if (!bureauProxyPaymentValue) {
      const { directDebitAuthorities } = participant;
      const activeCentralisedAccounts = directDebitAuthorities.filter(
        authority =>
          authority.endDate === null &&
          (authority.employers == null || authority.employers.length === 0)
      );

      if (activeCentralisedAccounts.length > 0) {
        const requestPromises = activeCentralisedAccounts.map(account =>
          ClearningHouseApi.deactivateDDA(
            clientParticipantNumber,
            account.directDebitAuthorityId
          )
        );

        Promise.all(requestPromises).then(() => {
          const updatedDirectDebitAuthorities = directDebitAuthorities.map(
            authority => {
              if (
                authority.employers != null &&
                authority.employers.length > 0
              ) {
                return authority;
              } else {
                return { ...authority, endDate: getDateInYYYYMMDDFormat() };
              }
            }
          );
          saveDirectDebitAuthorities(updatedDirectDebitAuthorities);
        });
      }
    }
    if (bureauProxyPaymentValue) {
      // YES proxy payment for bureau: deactivating the accounts from the employer level
      const { directDebitAuthorities } = participant;
      const activeEmployerDDAccounts = directDebitAuthorities.filter(
        authority =>
          authority.endDate === null &&
          authority.employers !== null &&
          authority.employers.length > 0
      );
      if (activeEmployerDDAccounts.length > 0) {
        const requestPromises = activeEmployerDDAccounts.map(account =>
          ClearningHouseApi.deactivateDDA(
            clientParticipantNumber,
            account.directDebitAuthorityId
          )
        );

        Promise.all(requestPromises).then(() => {
          const updatedDirectDebitAuthorities = directDebitAuthorities.map(
            authority => {
              if (
                authority.employers == null ||
                authority.employers.length === 0
              ) {
                return authority;
              } else {
                return { ...authority, endDate: getDateInYYYYMMDDFormat() };
              }
            }
          );
          saveDirectDebitAuthorities(updatedDirectDebitAuthorities);
        });
      }
    }
  };

  const handleOptionChange = event => {
    const { value } = event.target;

    if (value === 'false') {
      if (
        participant.directDebitAuthorities.find(
          authority =>
            authority.endDate === null &&
            (authority.employers == null || authority.employers.length === 0)
        )
      ) {
        // Inform the user of the consequence of change when there are existing accounts
        if (
          window.confirm(
            'Are you sure you want to remove previously saved trust account(s)?'
          )
        ) {
          updatedBureauProxyPayment(false);
        }
      } else {
        updatedBureauProxyPayment(false);
      }
    } else if (value === 'true') {
      if (
        participant.directDebitAuthorities.find(
          authority =>
            authority.endDate === null &&
            (authority.employers !== null || authority.employers.length > 0)
        )
      ) {
        // Inform the user of the consequence of change when there are existing accounts
        if (
          window.confirm(
            'Are you sure you want to remove previously saved trust account(s)?'
          )
        ) {
          updatedBureauProxyPayment(true);
        }
      } else {
        updatedBureauProxyPayment(true);
      }
    }
  };

  const handleDirectDebitChoice = event => {
    const { value } = event.currentTarget;
    setUseDirectDebit(value === 'true');
  };

  const initData = () => {
    dispatch(setNextEnabled(false));
    // TODO Move this to the fetch participants call

    if (participant.directDebitAuthorities.length === 0) {
      setUseDirectDebit(false);
    }
  };

  useEffect(
    () => {
      document.title = `Beam - Payment method`;
      if (participant && !isInitialized) {
        setInitialized(true);
        initData();
      }
    },
    [participant]
  );

  return (
    <>
      {!loading && (
        <div>
          <section className="Section Section--leading">
            {isBureau ? (
              <h2>Ways to pay super contributions</h2>
            ) : (
              <h2>Ways to pay your super contributions</h2>
            )}
            <PaymentOptions />
            {acceptDirectDebit && (
              <div>
                <h2>
                  <legend>Do you intend to use direct debit?</legend>
                </h2>
                <div
                  className="RadioGroup"
                  role="radiogroup"
                  aria-label="Use your own bank account for future super contribution"
                >
                  <div className="Radio">
                    <input
                      type="radio"
                      name="use-other-payment-method"
                      value="false"
                      id="use-other-payment-method"
                      checked={!useDirectDebit}
                      onChange={handleDirectDebitChoice}
                    />
                    <label htmlFor="use-other-payment-method">No</label>
                  </div>
                  <div className="Radio">
                    <input
                      type="radio"
                      name="use-direct-debit"
                      value="true"
                      id="use-direct-debit"
                      checked={useDirectDebit}
                      onChange={handleDirectDebitChoice}
                    />
                    <label htmlFor="use-direct-debit">Yes</label>
                  </div>
                </div>
              </div>
            )}
            {useDirectDebit &&
              isBureau && (
                <div>
                  <hr />
                  <h2>
                    <legend>
                      Do you intend to use direct debit from one primary bank
                      account?
                    </legend>
                  </h2>
                  <div
                    className="RadioGroup"
                    role="radiogroup"
                    aria-label="Use your own bank account for future super contribution"
                  >
                    <div className="Radio">
                      <input
                        type="radio"
                        name="managed-payment-no"
                        value="false"
                        id="managed-payment-no"
                        checked={!bureauProxyPayment}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="managed-payment-no">
                        No, I will use separate bank accounts for each employer
                      </label>
                    </div>
                    <div className="Radio">
                      <input
                        type="radio"
                        name="managed-payment-yes"
                        value="true"
                        id="managed-payment-yes"
                        checked={!!bureauProxyPayment}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="managed-payment-yes">
                        Yes, I want to use one account to pay all contributions
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </section>

          {useDirectDebit &&
            ((isBureau && bureauProxyPayment) || !isBureau) && (
              <PaymentSelection />
            )}

          <section className="Section PageNavGroup">
            <Button
              type="button"
              primary
              hollow
              customclass="Button--cancel"
              onClick={() => {
                dispatch(handleReturnToPayroll(payrollName, redirectUrl));
              }}
            >
              <span>Cancel</span>
            </Button>
            <Link
              className="Button Button--primary Button--hollow Button--prev"
              to={{
                pathname: '/your-details',
                hash: location.hash
              }}
            >
              <span>Back</span>
            </Link>
            <Button
              primary
              next
              onClick={navigateToNext}
              disabled={
                (!isBureau &&
                  onlyAcceptDirectDebit &&
                  participant.directDebitAuthorities.length === 0) ||
                (isBureau &&
                  bureauProxyPayment &&
                  participant.directDebitAuthorities.length === 0) ||
                loading
              }
            >
              <span>Next</span>
            </Button>
          </section>
        </div>
      )}
    </>
  );
};

export default withRouter(props => <PaymentMethod {...props} />);
