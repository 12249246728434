import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ART_FUND_DETAILS } from './../../utils/config';
import { getDateInYYYYMMDDFormat, isActive } from './../../utils/date';
import DefaultFundHeader from './DefaultFundHeader';
import SelectContainer from './../../components/Fieldsets/SelectContainer';
import Button from './../../components/Button/Button';
import WhySelectDefaultFund from './WhySelectDefaultFund';
import RegisteringWithART from '../../components/RegisteringWithART/RegisteringWithART';
import difference from 'lodash/difference';
import { useSelector, useDispatch } from 'react-redux';
import {
  setARTSelected,
  setEmployerRegisterSuper,
  setSelectedFunds
} from '../../store/actions/globalActions';
import { setNextEnabled } from '../../store/actions/globalActions';
import { handleReturnToPayroll } from '../../store/thunks/global';
import { saveParticipantDetails } from '../../store/thunks/participant';
import SearchApi from '../../store/api/search';
import { saveParticipantData } from '../../store/actions/participantActions';
import ClearningHouseApi from '../../store/api/clearningHouse';
import ValidationPanel from './../../components/ValidationPanel/ValidationPanel';

function usePrevious(value) {
  const ref = useRef();
  useEffect(
    () => {
      ref.current = value; //assign the value of ref to the argument
    },
    [value]
  ); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

const DefaultFund = props => {
  const [nascentUSI, setNascentUSI] = useState([]);
  const prevStateNascentUSI = usePrevious(nascentUSI);

  const [previousSaved, setPreviousSaved] = useState([]);
  const prevStatePreviousSaved = usePrevious(previousSaved);

  const [isInitilaized, setInitialized] = useState(false);

  const dispatch = useDispatch();
  const { participant, validations, payrollName, redirectUrl } = useSelector(
    state => state.participant.data
  );
  const { employerRegisterSuper, funds, isARTSelected } = useSelector(
    state => state.global.data
  );

  const [isValidating, setValitating] = useState(false);

  const handleChange = e => {
    const value = e.target.checked;
    dispatch(setEmployerRegisterSuper(value));
  };

  // Update defaultFunds to the non-bureau participant/employer - used in "DefaultFund" component
  const saveDefaultFundsForNonBureau = async defaultFunds => {
    const newParticipant = {
      ...participant,
      employers: [{ ...participant.employers[0], defaultFunds }]
    };
    try {
      setValitating(true);
      const res = await ClearningHouseApi.updateParticipant(newParticipant);
      if (res.validations.length === 0) {
        dispatch(
          saveParticipantData({ participant: newParticipant, validations: [] })
        );
        // Navigate to next step
        props.history.push({
          pathname: '/t&c',
          hash: props.location.hash
        });
      } else {
        dispatch(
          saveParticipantData({
            participant: newParticipant,
            validations: res.validations
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setValitating(false);
    }
  };

  const handleClick = () => {
    // Adding and removing updated set and navigate to T&C page
    const oldSet = previousSaved.map(fund => fund.usi);

    const newSet = funds.map(fund => fund.uniqueSuperannuationIdentifier);

    const removedSet = difference(oldSet, newSet);

    const newData = newSet.map(usi => ({
      endDate: null,
      startDate: getDateInYYYYMMDDFormat(),
      usi: usi
    }));

    const removedData = removedSet
      .map(usi => {
        return previousSaved.find(fund => fund.usi === usi);
      })
      .map(fund => {
        return {
          ...fund,
          endDate: getDateInYYYYMMDDFormat()
        };
      });

    saveDefaultFundsForNonBureau([...newData, ...removedData]);
  };

  const filterByEndDate = funds => {
    return funds.filter(fund => isActive(fund.endDate));
  };

  const getAllDefaultFundDetails = USIs => {
    // Do not search again if fund already in the list
    const existingFundUSIs = funds.map(
      fund => fund.uniqueSuperannuationIdentifier
    );
    const notFoundUSIs = USIs.filter(usi => !existingFundUSIs.includes(usi));
    if (notFoundUSIs.length > 0) {
      notFoundUSIs.forEach(usi => {
        SearchApi.getFundDetails('usi', usi)
          .then(fundsInfo => {
            if (fundsInfo[0]) {
              dispatch(setSelectedFunds([...funds, fundsInfo[0]]));
            }
          })
          .catch(err => console.warn(err));
      });
    }
  };

  const initData = () => {
    dispatch(setNextEnabled(false));
    const { employers } = participant;
    if (employers && employers.length > 0) {
      const { defaultFunds } = employers[0];
      if (defaultFunds && defaultFunds.length > 0) {
        // Filter out items that have validations errors
        const validFunds = defaultFunds.filter((fund, index) => {
          if (validations) {
            return !validations.find(validation =>
              validation.path.includes(`employers[0].defaultFunds[${index}]`)
            );
          }
          return true;
        });

        // Ignore inactive items
        const activeFunds = filterByEndDate(validFunds);

        const previousSaved = activeFunds.filter(
          fund => fund.defaultFundId != null
        );

        setPreviousSaved(previousSaved);
        const nascentUSI = activeFunds
          .filter(fund => typeof fund.defaultFundId == 'null')
          .map(fund => fund.usi);
        setNascentUSI(nascentUSI);
      }
    }
  };

  useEffect(
    () => {
      if (prevStatePreviousSaved == null) {
        getAllDefaultFundDetails(previousSaved);
      } else if (previousSaved.length !== prevStatePreviousSaved.length) {
        const newUSI = previousSaved
          .filter(usi => !prevStatePreviousSaved.includes(usi))
          .map(fund => fund.usi);
        getAllDefaultFundDetails(newUSI);
      }
    },
    [previousSaved]
  );

  useEffect(
    () => {
      // Get information for newly added fund(s)
      if (prevStateNascentUSI == null) {
        getAllDefaultFundDetails(nascentUSI);
      } else if (prevStateNascentUSI.length !== nascentUSI.length) {
        const newUSI = nascentUSI.filter(
          usi => !prevStateNascentUSI.includes(usi)
        );
        getAllDefaultFundDetails(newUSI);
      }
    },
    [nascentUSI]
  );

  useEffect(
    () => {
      document.title = `Beam - Default fund`;
      if (participant && !isInitilaized) {
        setInitialized(true);
        initData();
      }
    },
    [participant]
  );

  useEffect(
    () => {
      const hasARTSelected =
        funds &&
        funds.length > 0 &&
        funds
          .map(selectedFund => selectedFund.uniqueSuperannuationIdentifier)
          .indexOf(ART_FUND_DETAILS.uniqueSuperannuationIdentifier) > -1;
      dispatch(setARTSelected(hasARTSelected));
    },
    [funds]
  );

  return (
    <div>
      <section
        className="Section Section--leading"
        style={{ marginBottom: '2rem' }}
      >
        <DefaultFundHeader />
        <WhySelectDefaultFund />
      </section>
      <SelectContainer />
      {funds.length > 0 &&
        !isARTSelected && (
          <section className="Section">
            <RegisteringWithART
              employerRegisterART={employerRegisterSuper}
              onChange={handleChange}
            />
          </section>
        )}

      <div style={{ marginBottom: '3rem' }}>
        Before you make any decision about any financial products, always do
        your research. Read the relevant Product Disclosure Statement (PDS) and
        Target Market Determination (TMD). For a copy of the PDS and TMD for
        Super Savings products, you can find them at art.com.au/pds. Australian
        Retirement Trust’s Super Savings products are issued by Australian
        Retirement Trust Pty Ltd (ABN 88 010 720 840, AFSL No. 228975) as
        trustee for Australian Retirement Trust (ABN 60 905 115 063)
      </div>
      <section className="Section PageNavGroup">
        <Button
          type="button"
          primary
          hollow
          customclass="Button--cancel"
          disabled={isValidating}
          onClick={() => {
            dispatch(handleReturnToPayroll(payrollName, redirectUrl));
          }}
        >
          <span>Cancel</span>
        </Button>
        {!isValidating && (
          <Link
            className="Button Button--primary Button--hollow Button--prev"
            to={{
              pathname: '/payment-method',
              hash: props.location.hash
            }}
          >
            <span>Back</span>
          </Link>
        )}
        <Button
          primary
          next={!isValidating}
          disabled={funds.length === 0 || isValidating}
          title="Please select at least one default fund before proceed to the next step"
          onClick={handleClick}
        >
          <span>{isValidating ? 'Validating...' : 'Next'}</span>
        </Button>
      </section>
      {validations &&
        validations.length > 0 && (
          <ValidationPanel
            errors={validations.filter(validation =>
              validation.path.includes(`employers[0].defaultFunds[0]`)
            )}
          />
        )}
    </div>
  );
};

export default withRouter(props => <DefaultFund {...props} />);
