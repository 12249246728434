import React from 'react';
import ExternalLink from './../../components/ExternalLink/ExternalLink';

function YourDetailsFooter() {
  return (
    <section className="Section">
      Beam is issued by Precision Administration Services Pty Ltd (Precision), (ABN 47 098 977 667, AFSL No. 246604). Precision is wholly owned by Australian Retirement Trust Pty Ltd (ABN 88 010 720 840, AFSL No. 228975) as Trustee for the Australian Retirement Trust (ABN 60 905 115 063).  Australian Retirement Trust Products are issued by Australian Retirement Trust Pty Ltd.  The information about Beam on this application is provided by Precision.  The information on this application about Australian Retirement Trust is provided by Australian Retirement Trust Pty Ltd.  You should consider the relevant PDS before making a decision to acquire any financial product.  Precision is committed to protecting the privacy of information you provide.  
      
      View Precision’s{' '}
      <ExternalLink
        url="https://beamconnect.com.au/privacy-policy/"
        text="Privacy Policy"
      />
      .

  
    </section>
  );
}

export default YourDetailsFooter;




