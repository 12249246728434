import React from 'react';
import './Loader.css';

function Loader({ text, width = 150 }) {
  return (
    <div className="Loader">
      <svg
        version="1.1"
        x="0"
        y="0"
        width={width + 'px'}
        height={width + 'px'}
        viewBox="-10 -10 120 120"
        enableBackground="new 0 0 200 200"
      >
        <path
          className="circle"
          d="M0,50 A50,50,0 1 1 100,50 A50,50,0 1 1 0,50"
        />
      </svg>

      <span className="Loader-text">{text != null ? text : 'Loading'}...</span>
    </div>
  );
}

export default Loader;
