import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from './../../components/Button/Button';
import Callout from './../../components/Callout/Callout';
import Alert from './../../components/Alert/Alert';
import omit from 'lodash/omit';
import { getDateInYYYYMMDDFormat } from './../../utils/date';
import BankAccountSectionContainer from './../../components/Fieldsets/BankAccountSectionContainer';
import { isActive } from './../../utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { saveParticipantDetails } from '../../store/thunks/participant';
import ClearningHouseApi from '../../store/api/clearningHouse';
import { saveParticipantData } from '../../store/actions/participantActions';

const PaymentSelection = ({
  isEmployer = false,
  businessIdentifier,
  clientEmployerNumber
}) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [displayDirectDebitForm, setDisplayDirectDebitForm] = useState(false);
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(null);

  const { participant, validations } = useSelector(
    state => state.participant.data
  );

  const displayFormToAddAnotherAccount = () => {
    setDisplayDirectDebitForm(true);
    setSelectedAccountIndex(null);
  };

  const handleAddDirectDebitAuthority = newAuthority => {
    saveDirectDebitAuthorities(
      participant.directDebitAuthorities.concat(newAuthority)
    );
  };

  const saveDirectDebitAuthorities = directDebitAuthorities => {
    dispatch(
      saveParticipantDetails({
        ...participant,
        directDebitAuthorities: directDebitAuthorities.map(authority => ({
          ...authority,
          startDate: authority.startDate
            ? authority.startDate
            : getDateInYYYYMMDDFormat()
        }))
      })
    );
  };

  const handleRemoveDirectDebitAuthority = indexToRemove => {
    const newAuthorities = participant.directDebitAuthorities.filter(
      (authority, index) => index !== indexToRemove
    );
    saveDirectDebitAuthorities(newAuthorities);
  };

  const handleBankAccountRemoval = async () => {
    if (
      selectedAccountIndex != null &&
      window.confirm('Are you sure you want to remove this account?')
    ) {
      const removedAuthority = data.find(
        authority => authority.index === selectedAccountIndex
      );

      // Deactivating the authority from the list by setting the endDate if existing
      if (removedAuthority.directDebitAuthorityId !== 0) {
        setSelectedAccountIndex(null);
        try {
          await ClearningHouseApi.deactivateDDA(
            participant.clientParticipantNumber,
            removedAuthority.directDebitAuthorityId
          );
        } catch (error) {
          console.log(error);
        } finally {
          let directDebitAuthorities = data.map(authority =>
            authority.index === selectedAccountIndex
              ? { ...authority, endDate: getDateInYYYYMMDDFormat() }
              : authority
          );
          let allDirectDebitAuthorities = participant.directDebitAuthorities;
          directDebitAuthorities.forEach(authority => {
            allDirectDebitAuthorities[authority.index] = {
              ...allDirectDebitAuthorities[authority.index],
              ...omit(authority, ['index', 'validations'])
            };
          });
          saveDirectDebitAuthorities(allDirectDebitAuthorities);
          //dispatch(saveParticipantData({ participant: { ...participant, directDebitAuthorities: allDirectDebitAuthorities } }))
        }
      } else {
        // Drop the authority from the list directly if new account
        let directDebitAuthorities = data.filter(
          authority => authority.index !== selectedAccountIndex
        );

        // Update the data in state and try to remove the authority from the temporary store
        setSelectedAccountIndex(null);
        setData(directDebitAuthorities);
        handleRemoveDirectDebitAuthority(removedAuthority.index);
      }
    }
  };

  // Update existing bank account details or add a new one
  const handleBankAccountUpdate = (updatedIndex, newData) => {
    let directDebitAuthorities = [];
    let newAccount =
      clientEmployerNumber != null
        ? {
            ...newData,
            employers: [
              {
                clientEmployerNumber
              }
            ]
          }
        : newData;
    if (updatedIndex != null) {
      directDebitAuthorities = data.map(authority => {
        return updatedIndex === authority.index ? newAccount : authority;
      });
    } else {
      directDebitAuthorities = data.concat(newAccount);
    }
    setData(directDebitAuthorities);
    if (updatedIndex == null) {
      handleAddDirectDebitAuthority(newAccount);
    } else {
      saveDirectDebitAuthorities(directDebitAuthorities);
    }
  };

  useEffect(() => {
    if (participant) {
      // Find direct debit request validation errors at current level (participant)
      let directDebitAuthorityValidations = validations
        ? validations.filter(
            validation =>
              validation.path.indexOf('directDebitAuthorities') === 0
          )
        : [];

      // Extend each authority with its own validation errors
      let authorities = participant.directDebitAuthorities.map(
        (authority, index) => {
          return {
            ...authority,
            index,
            validations: [
              ...directDebitAuthorityValidations.filter(error =>
                error.path.includes(`directDebitAuthorities[${index}]`)
              )
            ]
          };
        }
      );

      // Filter out authorities that are for employers
      authorities = !clientEmployerNumber
        ? authorities.filter(
            authority =>
              authority.employers == null || authority.employers.length === 0
          )
        : authorities.filter(
            authority =>
              authority.employers &&
              authority.employers.find(
                employer =>
                  employer.clientEmployerNumber === clientEmployerNumber
              )
          );

      const defaultAuthorities = authorities.filter(
        authority =>
          authority.employers == null ||
          (authority.employers.length === 0 && isActive(authority.endDate))
      );

      const newData = authorities
        // Active authority needs to have an "endDate" that is unset or in the future
        .filter(authority => isActive(authority.endDate))
        .sort((a, b) => a.directDebitAuthorityId - b.directDebitAuthorityId);

      setData(newData);

      setDisplayDirectDebitForm(
        newData.length === 0 &&
          (defaultAuthorities === 'undefined' ||
            defaultAuthorities.length === 0)
      );
    }
  }, [participant]);

  return (
    <section className="Section">
      {isEmployer && <h3>Employer client bank details</h3>}
      {!isEmployer && <h3>Direct Debit Requests</h3>}
      {data && data.length > 0 && (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <p>
              Here are the active direct debit requests we’ve found for you.
            </p>
            <p>
              If you have more than one direct debit request listed, you can
              select which bank account to use when submitting contributions.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Callout position="right">
                <h3>
                  To remove a direct debit request, select the request and then
                  click "REMOVE"
                </h3>
              </Callout>
            </div>
            <div className="col-md-6">
              <ul className="SelectedList">
                {data.map(authority => {
                  const valid =
                    authority.validations && authority.validations.length > 0;

                  return (
                    <li
                      key={
                        authority.directDebitAuthorityId +
                        authority.account.bsb +
                        authority.account.accountNumber
                      }
                      className={classNames({
                        'is-invalid': valid,
                        'is-active': authority.index === selectedAccountIndex
                      })}
                    >
                      <Button
                        type="button"
                        onClick={() => setSelectedAccountIndex(authority.index)}
                      >
                        <span>
                          {authority.validations &&
                            authority.validations.length > 0 && <Alert />}
                        </span>
                        {authority.account.bsb}{' '}
                        {authority.account.accountNumber} (
                        {authority.account.accountName})
                      </Button>
                    </li>
                  );
                })}
              </ul>
              <Button
                type="button"
                primary
                hollow
                customclass={classNames({
                  'Button--add-another': true,
                  'is-active':
                    displayDirectDebitForm && selectedAccountIndex === null
                })}
                onClick={displayFormToAddAnotherAccount}
              >
                Add another
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}

      {(selectedAccountIndex != null || displayDirectDebitForm) && (
        <BankAccountSectionContainer
          {...data.find(authority => authority.index === selectedAccountIndex)}
          businessIdentifier={
            businessIdentifier
              ? businessIdentifier
              : participant.businessIdentifier
          }
          onChange={handleBankAccountUpdate}
          onRemove={handleBankAccountRemoval}
        />
      )}
    </section>
  );
};

export default PaymentSelection;
