import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({ location }) => (
  <div>
    <section className="Section Section--leading">
      <h1>
        No match for <code>{location.pathname}</code>
      </h1>
    </section>
    <Link
      className="Button Button--primary"
      to={{
        pathname: '/',
        hash: location.hash
      }}
    >
      Return to the first step
    </Link>
  </div>
);

export default NotFound;
