import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {
  ROUTES,
  BUREAU_ROUTES,
  STEPS,
  BUREAU_STEPS
} from './../../utils/config';
import './Progress.css';
import { useSelector } from 'react-redux';

function ProgressStep(props) {
  const { index, name, isActive, isFinished } = props;
  const btnClass = classNames({
    'is-active': isActive,
    'is-finished': isFinished
  });
  return (
    <li className={btnClass}>
      {index}. <span>{name}</span>
    </li>
  );
}

const Progress = ({ location }) => {
  const { isBureau } = useSelector(state => state.participant.data);
  const currentStep = isBureau
    ? BUREAU_ROUTES.indexOf(location.pathname)
    : ROUTES.indexOf(location.pathname);
  const steps = isBureau ? BUREAU_STEPS : STEPS;

  return (
    <div className="Progress">
      <ol className="Progress-list">
        {steps.map((step, index) => (
          <ProgressStep
            key={index}
            isFinished={index <= currentStep}
            isActive={index === currentStep}
            index={parseInt(index + 1, 10)}
            name={step}
          />
        ))}
      </ol>
    </div>
  );
};

export default withRouter(Progress);
