import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';

class ChannelApi {
  static async getPaymentOptions() {
    try {
      const response = await axios.get('/channel/paymentOptions');
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default ChannelApi;
