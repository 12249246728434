import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ValidationPanel.css';

class ValidationPanel extends Component {
  render() {
    const { errors } = this.props;

    return errors ? (
      <div className="ValidationPanel">
        <ul>
          {errors.map(error => (
            <li key={error.errorCode}>
              {error.errorCode}: <strong>{error.message}</strong>
            </li>
          ))}
        </ul>
      </div>
    ) : null;
  }
}

ValidationPanel.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ValidationPanel;
