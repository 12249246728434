import React, { Component } from 'react';
import Select from 'react-select';
import FormGroup from './../../components/FormGroup/FormGroup';
import validateABN from './../../utils/validateABN';
import validateWPN from './../../utils/validateWPN';
import {
  NAME_REGEX,
  ORG_NAME_MAX_LENGTH
} from './../../utils/validationConstants';
import { BUSINESS_IDENTIFIER_TYPES } from './../../utils/config';

// Convert an array of options to React-Select compliant format
function convertArrayToSelectOptions(array) {
  return array.map(item => ({
    value: item,
    label: item
  }));
}

class BusinessDetailsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        businessIdentifierType: '',
        value: '',
        organisationName: ''
      }
    };
  }

  handleInputChange = event => {
    const { name, value } = event.target;

    if (name === 'value') {
      this.props.onChange({
        businessIdentifier: {
          businessIdentifierType: this.props.businessIdentifier
            .businessIdentifierType,
          value: value.length > 0 && value.replace(/\s/g, '')
        }
      });
    } else {
      this.props.onChange({
        [name]: value
      });
    }
  };

  handleTypeSelectChange = option => {
    const { value } = this.props.businessIdentifier;
    this.props.onChange({
      businessIdentifier: {
        businessIdentifierType: option.value,
        value: value.length > 0 && value.replace(/\s/g, '')
      }
    });
  };

  handleInputBlur = event => {
    // Validate input field on blur event
    const { name, value, required } = event.target;

    let error = { ...this.state.error };

    // Set error message to "Required" when mandatory field is empty
    if (!value && required) {
      error[name] = 'Required';
    } else if (value) {
      switch (name) {
        // Validate number against the format algorithm based on its type
        case 'businessIdentifierType':
        case 'value':
          error.value =
            this.props.businessIdentifier.businessIdentifierType === 'ABN'
              ? validateABN(value)
                ? ''
                : 'The ABN provided is invalid, please check again.'
              : validateWPN(value)
              ? ''
              : 'The WPN provided is invalid, please check again.';
          break;
        case 'organisationName':
          error.organisationName = NAME_REGEX.test(value)
            ? ''
            : 'Please provide a valid organisation name.';
          break;
        default:
          break;
      }
    }
    this.setState({ error });
  };

  handleInputFocus = event => {
    const { name } = event.target;

    // Remove error message when user starts modifying field
    this.setState({
      error: {
        ...this.state.error,
        [name]: ''
      }
    });
  };

  prepopulateValiationErrors = validations => {
    let error = null;
    validations.forEach(validation => {
      if (validation.path === 'businessIdentifier') {
        error = {
          ...error,
          value: validation.message
        };
      } else {
        error = {
          ...error,
          [validation.path]: validation.message
        };
      }
    });
    this.setState(prevState => {
      return {
        error: { ...prevState.error, ...error }
      };
    });
  };

  componentDidMount() {
    if (this.props.validations && this.props.validations.length > 0) {
      this.prepopulateValiationErrors(this.props.validations);
    }
  }

  render() {
    const { businessIdentifier, organisationName, isEmployer } = this.props;
    const { businessIdentifierType, value } = businessIdentifier;
    const { error } = this.state;

    const eventHandlers = {
      onChange: this.handleInputChange,
      onFocus: this.handleInputFocus,
      onBlur: this.handleInputBlur
    };

    return (
      <fieldset className="FormSection">
        <div className="row">
          <div className="col-sm-4">
            <legend>
              {isEmployer ? 'Employer client' : 'Business'} details
            </legend>
          </div>
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-5">
                <div className="FormGroup">
                  <label htmlFor="businessIdentifierType">
                    Business Identifier Type{' '}
                    <span className="Form-required">*</span>
                  </label>
                  <Select
                    name="businessIdentifierType"
                    value={businessIdentifierType || ''}
                    options={convertArrayToSelectOptions(
                      BUSINESS_IDENTIFIER_TYPES
                    )}
                    required={true}
                    clearable={false}
                    onChange={this.handleTypeSelectChange}
                  />
                  {error.businessIdentifierType &&
                    error.businessIdentifierType.length > 0 && (
                      <div className="Form-error">
                        {error.businessIdentifierType}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-sm-7">
                <FormGroup
                  name="value"
                  id="value"
                  label="Business Identifier Number"
                  required={true}
                  value={(value && value.replace(/\s/g, '')) || ''}
                  error={error.value}
                  {...eventHandlers}
                />
              </div>
            </div>
            <FormGroup
              name="organisationName"
              id="organisationName"
              label="Business Name"
              hint="200 characters or less, special characters (<, >, +, etc.) not allowed"
              required={true}
              maxLength={ORG_NAME_MAX_LENGTH}
              value={organisationName || ''}
              error={error.organisationName}
              {...eventHandlers}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}

BusinessDetailsSection.defaultProps = { isEmployer: false };

export default BusinessDetailsSection;
